import React from 'react';
import { crossIcon } from '../../../assets/svg';
import Svg from '../Svg/Svg';

import styles from './CrossBtn.module.scss';

function CrossBtn({ onClick, iconClassName, btnClassName, isPositionSet }) {
    return (
        <button
            className={
                `${styles['crossBtn']} ` +
                `${btnClassName ? btnClassName : ''} ` +
                `${isPositionSet ? '' : styles['crossBtn_positionDef']}`
            }
            onClick={onClick}
        >
            <Svg
                className={
                    iconClassName ? iconClassName : styles['crossBtn__icon']
                }
                id={crossIcon}
            />
        </button>
    );
}

export default CrossBtn;
