import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { bscIcon, ethIcon, solIcon } from '../../../../assets/svg';
import BalanceItem from './BalanceItem';
import BalanceModal from './BalanceModal';
import styles from './styles.module.scss';

const BalanceBlock = () => {
    const user = useSelector((state) => state.auth.user);

    console.log(user);

    const chains = [
        {
            chain: 'Solana',
            icon: solIcon,
            balance: user?.sol_balance || 0,
            contract: user?.sol_contract || 'unavailable',
            token: 'SOL',
            wallet: user?.sol_wallet || '',
            kind: 'Sol',
        },
        {
            chain: 'Ethereum',
            icon: ethIcon,
            balance: user?.eth_balance || 0,
            contract: user?.eth_contract || 'unavailable',
            token: 'ETH',
            wallet: user?.eth_wallet || '',
            kind: 'Eth',
        },
        {
            chain: 'BSC',
            icon: bscIcon,
            balance: user?.bsc_balance || 0,
            contract: user?.bsc_contract || 'unavailable',
            token: 'BNB',
            wallet: user?.bsc_wallet || '',
            kind: 'Bsc',
        },
    ];
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [withdrawChain, setWithdrawChain] = useState('');
    const [withdrawToken, setWithdrawToken] = useState('');

    const handleCopy = (contract) => {
        navigator.clipboard
            .writeText(contract)
            .then(() => {
                setIsTooltipVisible(true);
                setTimeout(() => {
                    setIsTooltipVisible(false);
                }, 3000);
            })
            .catch((err) => {
                console.error('Ошибка при копировании текста: ', err);
            });
    };

    const handleSetModal = (chain, token) => {
        setIsModalOpened(true);
        setWithdrawChain(chain);
        setWithdrawToken(token);
    };

    return (
        <div className={styles['balanceBlock']}>
            <div className={styles['balanceBlock__list']}>
                {chains.map((chain, index) => (
                    <BalanceItem
                        {...chain}
                        key={index}
                        handleCopy={handleCopy}
                        handleSetModal={handleSetModal}
                    />
                ))}
            </div>

            {isModalOpened && (
                <BalanceModal
                    userId={user?.id}
                    withdrawChain={withdrawChain}
                    withdrawToken={withdrawToken}
                    onCloseModal={() => {
                        setIsModalOpened(false);
                    }}
                />
            )}

            {isTooltipVisible && (
                <div className={styles['copyTooltip']}>Адрес скопирован!</div>
            )}
        </div>
    );
};

export default BalanceBlock;
