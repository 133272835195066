import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WidgetBlock from '../../components/SniperPage/WidgetBlock';
import { ErrorFallback } from '../../components/SniperPage/WidgetBlock/ErrorFallback';
import { sniperBlockchains } from '../../constants';
import { useSniperSocket } from '../../hooks/useSniperSocket';
import {
    getQuote,
    getQuoteLoadingError,
    getQuoteLoadingStart,
    requestTokens,
} from '../../redux/action/sniper';

const Widget = () => {
    const dispatch = useDispatch();
    const tokens = useSelector((state) => state.sniper.tokens);
    const user = useSelector((state) => state.auth.user);
    const data = useSelector((state) => state.sniper.quote);

    const [token, setToken] = useState(null);
    const [blockchain, setBlockchain] = useState(sniperBlockchains[0]);
    const [side, setSide] = useState('buy');
    const [amount, setAmount] = useState(0);

    const [quoteRequest, setQuoteRequest] = useState({
        event: 'quote',
        id: user?.id,
        blockchain: blockchain.value,
        side: side,
        amount: amount,
        token_address: token?.address || '',
    });

    useEffect(() => {
        if (blockchain) {
            dispatch(requestTokens(blockchain.value));
        }
    }, [dispatch, blockchain]);

    useEffect(() => {
        if (blockchain) {
            setQuoteRequest((prev) => {
                return { ...prev, blockchain: blockchain.value };
            });
        }
        if (token) {
            setQuoteRequest((prev) => {
                return { ...prev, token_address: token.address };
            });
        }
        if (side) {
            setQuoteRequest((prev) => {
                return { ...prev, side: side };
            });
        }
        if (amount) {
            setQuoteRequest((prev) => {
                return { ...prev, amount: amount };
            });
        }
    }, [blockchain, token, side, amount]);

    useSniperSocket(
        quoteRequest,
        (message) => dispatch(getQuote(message)),
        () => dispatch(getQuoteLoadingStart),
        (error) => dispatch(getQuoteLoadingError(error))
    );

    const amountOut = data?.amount_out || 0;
    const initGasPrice = data?.gas_price || 0;
    const tvl = data?.tvl || 0;

    const amountChangeHandler = (newAmount) => {
        setAmount(newAmount);
    };
    const sideChangeHandler = (newSide) => {
        setSide(newSide);
    };
    const blockchainChangeHandler = (newChain) => {
        setBlockchain(newChain);
    };
    const tokenChangeHandler = (newToken) => {
        setToken(newToken);
    };

    if (data?.error) {
        let errorMsg = 'Unexpected error...';

        if (typeof data?.error === 'string') {
            errorMsg = data?.error;
        }

        return (
            <article className="container">
                <main className="sniperContainer">
                    <ErrorFallback errorDetails={errorMsg} />
                </main>
            </article>
        );
    }

    return (
        <article className="container">
            <main className="sniperContainer">
                <WidgetBlock
                    sideChangeHandler={sideChangeHandler}
                    amountChangeHandler={amountChangeHandler}
                    blockchainChangeHandler={blockchainChangeHandler}
                    blockchainsList={sniperBlockchains}
                    tokenChangeHandler={tokenChangeHandler}
                    tokensList={tokens}
                    tvl={tvl}
                    amountIn={quoteRequest.amount}
                    amountOut={amountOut}
                    blockchain={quoteRequest.blockchain}
                    token={token}
                    side={quoteRequest.side}
                    userWallet={user?.wallet}
                    id={user?.id}
                    initialGasPrice={initGasPrice}
                />
            </main>
        </article>
    );
};

export default Widget;
