import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    createPaymentLink,
    fetchRequest,
} from '../../../redux/action/fetchTools';
import MainBtn from '../../layout/MainBtn/MainBtn';
import { infoMessages, sectionPrices, sectionsFullNames } from './config';
import CurrentSection from './CurrentSection';
import NewSection from './NewSection';
import SubModal from './SubModal';
import styles from './SubscriptionBlock.module.scss';

function SubscriptionBlock() {
    const user = useSelector((state) => state.auth.user);
    const userSubscriptions = useMemo(
        () => user?.subscription.split(',') || [],
        [user]
    );

    const [newSubPrice, setNewSubPrice] = useState(0);
    const [newSubscriptions, setNewSubscriptions] = useState([]);
    const [message, setMessage] = useState('');
    const [subLink, setSubLink] = useState('');
    const [showSubModal, setShowSubModal] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        const initialSections = Object.entries(sectionPrices)
            .filter(([key]) => userSubscriptions.includes(key))
            .map(([key, price]) => ({
                section: key,
                price,
            }));
        setNewSubscriptions(initialSections);
        setNewSubPrice(
            initialSections.reduce((total, sub) => total + sub.price, 0)
        );
    }, [userSubscriptions]);

    const displayMessage = (msg) => {
        setMessage(msg);
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 3000);
    };

    const displaySubMessage = (msg) => {
        setSubLink(msg);
        setShowSubModal(true);
    };

    const addSubscription = (section) => {
        setNewSubscriptions((prev) => {
            if (prev.some((sub) => sub.section === section)) {
                displayMessage(infoMessages.duplicate);
                return prev;
            }
            const price = sectionPrices[section] || 0;
            setNewSubPrice((prevPrice) => prevPrice + price);
            return [...prev, { section, price }];
        });
    };

    const removeSubscription = (index) => {
        setNewSubscriptions((prev) => {
            const removed = prev[index];
            setNewSubPrice((prevPrice) => prevPrice - removed.price);
            return prev.filter((_, i) => i !== index);
        });
    };

    const submitSubscription = async () => {
        if (newSubscriptions.length === 0) {
            displayMessage(infoMessages.atLeastOne);
            return;
        }

        const subNames = newSubscriptions.map((sub) => sub.section);

        const resLink = await fetchRequest(createPaymentLink, 'POST', {
            id: user.id,
            amount: newSubPrice,
            subscription: subNames,
        });

        displaySubMessage(resLink.payment_link);
    };

    return (
        <section className={styles['subscriptionBlock__container']}>
            {showMessage && (
                <div className={styles['subscriptionBlock__infoModal']}>
                    {message}
                </div>
            )}

            {showSubModal && (
                <SubModal
                    onClose={() => setShowSubModal(false)}
                    link={subLink}
                />
            )}

            <div className={styles['subscriptionBlock__box']}>
                <h3>Текущая подписка</h3>
                <div
                    className={`${styles['subscriptionBlock__currentSections']} scrollbarDef`}
                >
                    {userSubscriptions.map((key) => (
                        <CurrentSection
                            key={key}
                            price={sectionPrices[key] || 'free'}
                            name={sectionsFullNames[key]}
                        />
                    ))}
                </div>

                <h3 className={styles['subscriptionBlock__priceBlock']}>
                    <span>Цена: </span>
                    <span className={styles['subscriptionBlock__price']}>
                        {userSubscriptions.reduce(
                            (total, key) => total + (sectionPrices[key] || 0),
                            0
                        )}
                        $
                    </span>
                </h3>
            </div>

            <div className={styles['subscriptionBlock__box']}>
                <h3>Новая подписка</h3>
                <div className={styles['subscriptionBlock__newSubsContainer']}>
                    <div className={styles['subscriptionBlock__newSubsInner']}>
                        <h4>Доступные разделы</h4>
                        <div
                            className={`${styles['subscriptionBlock__newSubsBlock']} scrollbarDef`}
                        >
                            {Object.entries(sectionsFullNames).map(
                                ([sectionKey, name]) => (
                                    <div
                                        key={sectionKey}
                                        className={
                                            styles[
                                                'subscriptionBlock__newSection'
                                            ]
                                        }
                                    >
                                        <div>
                                            {name} -
                                            <span
                                                className={
                                                    styles[
                                                        'subscriptionBlock__price'
                                                    ]
                                                }
                                            >
                                                (
                                                {sectionPrices[sectionKey] || 0}
                                                $)
                                            </span>
                                        </div>

                                        <MainBtn
                                            onClick={() =>
                                                addSubscription(sectionKey)
                                            }
                                        >
                                            Добавить
                                        </MainBtn>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                    <div className={styles['subscriptionBlock__newSubsInner']}>
                        <h4>Выбранные разделы</h4>
                        <div
                            className={`${styles['subscriptionBlock__newSectionsBlock']} scrollbarDef`}
                        >
                            {newSubscriptions.map((sub, index) => (
                                <NewSection
                                    key={index}
                                    price={sub.price}
                                    section={sectionsFullNames[sub.section]}
                                    onRemove={() => removeSubscription(index)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className={styles['subscriptionBlock__submitBox']}>
                    <h3 className={styles['subscriptionBlock__priceBlock']}>
                        <span>Итоговая цена: </span>
                        <span className={styles['subscriptionBlock__price']}>
                            {newSubPrice}$
                        </span>
                    </h3>
                    <MainBtn onClick={submitSubscription}>
                        Оформить подписку
                    </MainBtn>
                </div>
            </div>
        </section>
    );
}

export default SubscriptionBlock;
