import React from 'react';

import { useLocation } from 'react-router-dom';

const TrackerPageHeader = () => {
    const { hash } = useLocation();

    return (
        <h5 className={`pageRouteTitle`}>
            Tracker
            
        </h5>
    );
};

export default TrackerPageHeader;
