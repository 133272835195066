import React, { useEffect, useState } from 'react';

import NewPortalProvider from '../../../../providers/NewPortalProvider';
import TransitionProvider from '../../../../providers/TransitionProvider';
import Backdrop from '../../../layout/Backdrop/Backdrop';
import Svg from '../../../layout/Svg/Svg';
import SideBarFooter from './SideBarFooter';
import SideBarLinks from './SideBarLinks';

import styles from './styles.module.scss';

function SideBar({ burgerOpened, onCloseBurger, isMobile }) {
    const [isCompact, setIsCompact] = useState(true);
    const [openLink, setOpenLink] = useState(null);
    const [isClosing, setIsClosing] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        setIsCompact(false);
        setOpenLink(null);
        setIsClosing(false);
        setIsAnimating(false);
    }, []);

    const handleCloseClick = () => {
        setOpenLink(null);
        
        setTimeout(() => {
            setIsCompact(!isCompact);
        }, 100);
    };
    

    useEffect(() => {
        if (burgerOpened) {
            if (!isCompact) {
                document.body.style.overflow = 'hidden';
            }

            if (isCompact) {
                document.body.classList.add('navbarCompactOpened');
                document.body.classList.remove('navbarFullOpened');
            } else {
                document.body.classList.add('navbarFullOpened');
                document.body.classList.remove('navbarCompactOpened');
            }
        } else {
            document.body.style.overflow = 'auto';
            document.body.classList.remove(
                'navbarCompactOpened',
                'navbarFullOpened'
            );
        }
    }, [burgerOpened, isCompact]);

    const handleBackdropClick = (e) => {
        e.stopPropagation();
    };

    return (
        <>
            <Backdrop
                inProp={burgerOpened}
                enableScroll={true}
                className={styles['sideBar__backdrop']}
            />
            <NewPortalProvider>
                <div
                    className={styles['sideBar__wrapper']}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1000,
                        pointerEvents: 'none',
                        display: burgerOpened ? 'block' : 'none',
                    }}
                    onClick={handleBackdropClick}
                >
                    <TransitionProvider
                        inProp={burgerOpened}
                        style={'left'}
                        className={`
                            ${styles['sideBar']} 
                            ${isCompact ? styles['compact'] : ''} 
                            ${isMobile ? styles['sideBar_mobile'] : ''}
                        `}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <SideBarLinks
                            isMobile={isMobile}
                            onCloseBurger={onCloseBurger}
                            isCompact={isCompact}
                            onToggleCompact={() => setIsCompact(!isCompact)}
                            isClosing={isClosing}
                            openLink={openLink}
                            setOpenLink={setOpenLink}
                        />
                        <button
                            className={`${styles['sideBar__closeBtn']} ${isCompact ? styles['sideBarLinks__closeBtn_compact'] : ''}`}
                            onClick={() => handleCloseClick(!isCompact)}
                        >
                            <Svg
                                className={styles['sideBar__closeIcon']}
                                id={isCompact ? 'arrowCompact' : 'arrow'}
                            />
                        </button>
                        <SideBarFooter
                            isCompact={isCompact}
                            isMobile={isMobile}
                            onCloseBurger={onCloseBurger}
                        />
                    </TransitionProvider>
                </div>
            </NewPortalProvider>
        </>
    );
}

export default SideBar;
