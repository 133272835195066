export const burgerIcon = 'burgerIcon';
export const settingsIcon = 'settings';
export const subscriptionIcon = 'subscription';
export const arrowIcon = 'arrow';
export const logOutIcon = 'logOut';
export const telegramIcon = 'telegram';
export const youtubeIcon = 'youtube';
export const twitterIcon = 'twiter';
export const supportIcon = 'support';
export const arbitrageIcon = 'arbitrage';
export const ArbitrageIconActive = 'arbitrageActive';
export const newsIcon = 'news';
export const arrowDownIcon = 'arrowDown';
export const successIcon = 'success';
export const arrowWithLineIcon = 'arrowWithLine';
export const errorIcon = 'error';
export const crossIcon = 'cross';
export const removeIcon = 'remove';
export const emailIcon = 'email';
export const FirstName = 'name';
export const Subject = 'subject';
export const eyeIcon = 'eye';
export const clockIcon = 'clock';
export const refreshIcon = 'refresh';
export const emptyIcon = 'empty';
export const deleteIcon = 'delete';
export const cexTracker = 'cexTracker';
export const dexTracker = 'dexTracker';
export const futures = 'futures';
export const outflowIcon = 'outflow';
export const externalLink = 'externalLink';
export const trackerLink = 'trackerLink';
export const trackerLinkActive = 'trackerLinkActive';
export const blacklistIcon = 'blacklist';
export const hideneyeIcon = 'hideneye';
export const filtersIcon = 'filters';
export const compactSocialIcon = 'compactSocialIcon';
export const closedArbitrageIcon = 'closedArbitrage';
export const closedArbitrageIconActive = 'closedArbitrageActive';
export const reportFakeIcon = 'reportFake';
export const walletIcon = 'wallet';
export const webIcon = 'web';
export const ethIcon = 'eth';
export const solIcon = 'sol';
export const bscIcon = 'bsc';
export const arrowDownSquareIcon = 'arrowDownSquare';
export const infoIcon = 'info';
export const arrowDownWithLines = 'arrowDownWithLines';
export const outflowsIcon = 'outflows';
export const sniperIcon = 'sniperLink';
export const listingIcon = 'listing';
export const documentIcon = 'document';
export const fallbackIcon = 'fallback';
export const sniperSideIcon = 'sniperSide';
export const lightningActive = 'lightning-icon';
