import React from 'react';
import styles from './styles.module.scss';

const SelectFilter = ({
    isSelectOpened,
    onToggleSelect,
    chosenOption,
    onChooseSelect,
    options,
}) => {
    return (
        <div className={styles[`selectContainer`]}>
            <div
                className={`${styles['customSelect']} ${isSelectOpened && styles['customSelect_opened']}`}
                tabIndex={0}
            >
                <div
                    className={styles['customSelect_selected']}
                    onClick={onToggleSelect}
                >
                    {chosenOption.charAt(0).toUpperCase() +
                        chosenOption.slice(1)}
                </div>
                <div
                    className={`${styles['selectItems']} ${isSelectOpened && styles['selectItems_opened']}`}
                >
                    {isSelectOpened &&
                        options.map(({ title, value }, optionIndex) => (
                            <div
                                key={optionIndex}
                                className={styles['selectOption']}
                                onClick={() =>
                                    onChooseSelect(title, value, title)
                                }
                            >
                                {title}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default SelectFilter;
