import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import backgroundImage from '../../assets/images/fon.jpg';


import { subscriptionTypes } from '../../constants';
import { checkIsLoggedIn, onUserSubscribe } from '../../redux/action/auth';
import { routes } from '../../router/path';
import { getLSItem } from '../../utils/functions/localStorage';
import { subscribePush } from '../../utils/functions/pushNotification';
import { lsProps } from '../../utils/lsProps';
import Navbar from '../global/Navbar/Navbar';
// import VideoBg from '../global/videoBg/VideoBg';

const App = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    
    // const location = useLocation();

    useEffect(() => {
        dispatch(checkIsLoggedIn());
    }, [dispatch]);

    

    useEffect(() => {
        if (!user) return;
        let usePush = getLSItem(lsProps.usePushNot, true);
        const pushendpoint = getLSItem(lsProps.pushendpoint);
        if (
            'Notification' in window &&
            user.subscription === subscriptionTypes.arb &&
            (usePush || usePush === null) &&
            !pushendpoint
        ) {
            subscribePush((sub) => dispatch(onUserSubscribe(sub)), usePush);
        }
    }, [user, dispatch]);

    return (
        <div style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            minHeight: '100vh',
            width: '100%'
        }}>
            {/* <VideoBg /> */}
            <Navbar />
            <Routes>
                {routes.map(({ path, component }, index) => (
                    <Route path={path} element={component} key={index} />
                ))}
            </Routes>
        </div>
    );
};

export default App;
