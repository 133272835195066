import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

const apiKey = process.env.REACT_APP_API_KEY;
const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
const projectId = process.env.REACT_APP_PROJECT_ID;
const storageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
const appId = process.env.REACT_APP_APP_IP;
const measurmentId = process.env.REACT_APP_MEASUREMENT_ID;

export const firebaseConfig = {
    apiKey,
    authDomain,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurmentId,
};

export const subscribePush = (clb) => {
    Notification.requestPermission().then((type) => {
        if (type === 'granted') {
            serviceWorkerRegistration.register(clb);
        }
    });
};

export const initializeFirebaseServices = () => {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
    return getMessaging(app);
};

export const requestNotificationPermission = async () => {
    return await Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            return true;
        } else {
            console.log('Permission not granted for notifications.');
            return false;
        }
    });
};
