import React from 'react';
import { Navigate } from 'react-router-dom';
import { getLSItem } from '../utils/functions/localStorage';
import { lsProps } from '../utils/lsProps';
import { mainPagePath } from './path';

const PrivateRoute = ({ element, noAuth, isAdmin }) => {
    const token = getLSItem(lsProps.token);
    const user = getLSItem(lsProps.user, true);
    const isAuthenticated =
        !token || !user || (isAdmin && user.role !== 'admin');
    const statement = noAuth ? !isAuthenticated : isAuthenticated;

    return statement ? <Navigate to={mainPagePath} replace={true} /> : element;
};

export default PrivateRoute;
