import {
    editEmailImage,
    editUsernameImage,
    passwordKeyImage,
} from '../../../assets/images';
import { togglePush } from '../../../redux/action/notifications';

export const getPassFields = () => [
    {
        icon: passwordKeyImage,
        placeholder: 'Старый пароль',
        key: 'old_password',
        type: 'password',
    },
    {
        icon: passwordKeyImage,
        placeholder: 'Новый пароль',
        key: 'new_password',
        type: 'password',
    },
];

export const getUserFields = (user) => [
    {
        icon: editEmailImage,
        placeholder: 'Email',
        key: 'email',
        type: 'email',
        initialValue: user?.email || '',
        title: 'EMAIL'
    },
    {
        icon: editUsernameImage,
        placeholder: 'Имя пользователя',
        key: 'username',
        initialValue: user?.username || '',
        title: 'USER AGE'
    },
];

export const getCommonButtons = (user) => [
    {
        text: 'Attach telegram',
        onClick: () => {
            const username = process.env.REACT_APP_TG_BOT_USERNAME;
            const slicedId = String(Math.abs(user?.id)).slice(-7);

            const generateRandomDigits = (length) => {
                let randomDigits = '';
                for (let i = 0; i < length; i++) {
                    randomDigits += Math.floor(Math.random() * 10);
                }
                return randomDigits;
            };

            const randomDigits = generateRandomDigits(32 - slicedId.length);
            const telegramUrl = `https://t.me/${username}?start=${randomDigits + slicedId}`;
            window.open(telegramUrl, '_blank');
        },
    },
];

export const getNotificationButtons = () => [
    {
        title: 'Push-уведомления',
        description:
            'Активируйте уведомления для браузера, чтобы быть в курсе всех событий и обновлений.',
        onTogglePush: togglePush,
    },
    {
        section: 'arbitrage',
        title: 'Arbitrage',
        description:
            'Включите уведомления для получения информации о новых арбитражных возможностях.',
    },
    {
        section: 'closedArbitrage',
        title: 'Closed Arbitrage',
        description: 'Получайте уведомления о завершенных арбитражных делах.',
    },
    {
        section: 'tracker',
        title: 'Tracker',
        description:
            'Получайте уведомления о статусах и обновлениях в трекере.',
    },
    {
        section: 'cexOutflows',
        title: 'CEX Outflows',
        description:
            'Включите уведомления для получения информации выводах средств из CEX.',
    },
];
