import {
    GET_QUOTE_ERROR,
    GET_QUOTE_LOADING_START,
    GET_QUOTE_SUCCESS,
    GET_SWAP_ERROR,
    GET_SWAP_LOADING_START,
    GET_SWAP_SUCCESS,
    GET_TOKENS_ERROR,
    GET_TOKENS_LOADING_START,
    GET_TOKENS_SUCCESS,
} from '../types';
import { authConfig, fetchRequest, getTokensUrl } from './fetchTools';

export const getQuote = (payload) => (dispatch) => {
    dispatch({ type: GET_QUOTE_SUCCESS, payload });
};

export const getQuoteLoadingStart = () => (dispatch) => {
    dispatch({ type: GET_QUOTE_LOADING_START });
};

export const getQuoteLoadingError = (payload) => (dispatch) => {
    dispatch({ type: GET_QUOTE_ERROR, payload });
};

export const getSwap = (payload) => (dispatch) => {
    dispatch({ type: GET_SWAP_SUCCESS, payload });
};

export const getSwapLoadingStart = () => (dispatch) => {
    dispatch({ type: GET_SWAP_LOADING_START });
};

export const getSwapLoadingError = (payload) => (dispatch) => {
    dispatch({ type: GET_SWAP_ERROR, payload });
};

export const getTokens = (payload) => (dispatch) => {
    dispatch({ type: GET_TOKENS_SUCCESS, payload });
};

export const requestTokens = (blockchain) => async (dispatch) => {
    dispatch({ type: GET_TOKENS_LOADING_START });

    try {
        const data = await fetchRequest(
            getTokensUrl,
            'POST',
            { blockchain: blockchain },
            authConfig(),
            true
        );
        dispatch(getTokens(data || []));
    } catch (err) {
        dispatch({ type: GET_TOKENS_ERROR, err });
    }
};
