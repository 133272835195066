import React from 'react';

import ArbitragePageHeader from '../../components/ArbitragePage/ArbitragePageHeader/ArbitragePageHeader';
import ArbitragePageList from '../../components/ArbitragePage/ArbitragePageList/ArbitragePageList';
import { requestArbitrage } from '../../redux/action/arbitrage';

function ArbitragePage() {
    return (
        <div className={'gradientBg'}>
            <div className="container">
                <ArbitragePageHeader arbitrageType="Arbitrage" />
                <ArbitragePageList
                    arbitrageType="Arbitrage"
                    socketSubscrition="arb"
                    restApiFunction={requestArbitrage}
                />
            </div>
        </div>
    );
}

export default ArbitragePage;
