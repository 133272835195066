import {
    GET_FUNDING_ERROR,
    GET_FUNDING_LOADING_START,
    GET_FUNDING_SUCCESS,
} from '../types';
import { fetchRequest, getFundingUrl, getFuturesUrl } from './fetchTools';

export const getFunding = (payload) => (dispatch) => {
    dispatch({ type: GET_FUNDING_SUCCESS, payload });
};

export const requestFunding = () => async (dispatch) => {
    dispatch({ type: GET_FUNDING_LOADING_START });
    try {
        const data = await fetchRequest(getFundingUrl);
        dispatch(getFunding(data || []));
    } catch (err) {
        dispatch({ type: GET_FUNDING_ERROR });
    }
};

export const requestFutures = () => async (dispatch) => {
    dispatch({ type: GET_FUNDING_LOADING_START });
    try {
        const data = await fetchRequest(getFuturesUrl);
        dispatch(getFunding(data || []));
    } catch (err) {
        dispatch({ type: GET_FUNDING_ERROR });
    }
};
