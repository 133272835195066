import React from 'react';
import { crossIcon, externalLink } from '../../../../assets/svg';
import Svg from '../../../layout/Svg/Svg';
import styles from '../SubscriptionBlock.module.scss';
import { infoMessages } from '../config';

const SubModal = ({ onClose, link }) => {
    return (
        <div className={styles['subscriptionBlock__subModal']}>
            <div className={styles['subscriptionBlock__subModalContainer']}>
                <div className={styles['subscriptionBlock__subModalHeader']}>
                    <h3>Оплата подписки</h3>
                    <button
                        onClick={onClose}
                        className={
                            styles['subscriptionBlock__subModalHeaderBtn']
                        }
                    >
                        <Svg
                            className={
                                styles[
                                    'subscriptionBlock__subModalHeaderCrossIcon'
                                ]
                            }
                            id={crossIcon}
                        />
                    </button>
                </div>
                <p>{infoMessages.success}</p>
                <a
                    className={styles['subscriptionBlock__subModalLink']}
                    target={'_blank'}
                    rel="noreferrer"
                    href={link}
                >
                    Оплатить подписку
                    <Svg
                        className={
                            styles['subscriptionBlock__subModalLinkIcon']
                        }
                        id={externalLink}
                    />
                </a>
            </div>
        </div>
    );
};

export default SubModal;
