import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { walletIcon } from '../../../../../assets/svg';
import { changeUserData } from '../../../../../redux/action/auth';
import MainBtn from '../../../../layout/MainBtn/MainBtn';
import MainInput from '../../../../layout/MainInput/MainInput';
import Svg from '../../../../layout/Svg/Svg';
import styles from './styles.module.scss';

const BalanceItem = ({
    chain,
    icon,
    balance,
    token,
    wallet,
    contract,
    kind,
    handleCopy,
    handleSetModal,
}) => {
    const dispatch = useDispatch();
    const [newWallet, setNewWallet] = useState('');

    useEffect(() => {
        setNewWallet(wallet);
    }, [wallet]);

    return (
        <>
            <div className={styles['balanceItem__balance']}>
                <div className={styles['balanceItem__balanceInfo']}>
                    <Svg
                        className={styles['balanceItem__chainIcon']}
                        id={icon}
                    />
                    <p>
                        {chain}: {balance} {token}
                    </p>
                </div>
                <div className={styles['balanceItem__contractBtns']}>
                    <MainBtn
                        onClick={() => {
                            handleSetModal(chain, token);
                        }}
                        className={styles['balanceItem__contractBtn']}
                    >
                        Вывести
                    </MainBtn>
                    <MainBtn
                        onClick={() => {
                            handleCopy(contract);
                        }}
                        className={styles['balanceItem__contractBtn']}
                    >
                        Скопировать контракт
                    </MainBtn>
                </div>
            </div>
            <div className={styles['balanceItem__balance']}>
                <div className={styles['balanceItem__balanceInfo']}>
                    <Svg
                        className={styles['balanceItem__walletIcon']}
                        id={walletIcon}
                    />
                    <MainInput
                        placeholder="Не привязан"
                        onChange={(e) => {
                            setNewWallet(e.target.value);
                        }}
                        value={newWallet}
                    />
                </div>

                <MainBtn
                    onClick={() => {
                        dispatch(
                            changeUserData({
                                [`${kind}Wallet`]: newWallet,
                            })
                        );
                    }}
                    className={styles['balanceItem__saveBtn']}
                >
                    Сохранить
                </MainBtn>
            </div>
        </>
    );
};

export default BalanceItem;
