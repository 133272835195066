import React from 'react';
import { useDispatch } from 'react-redux';
import { telegramIcon, webIcon } from '../../../../assets/svg';
import Svg from '../../../layout/Svg/Svg';
import styles from './styles.module.scss';

const NotificationsButtonBox = ({
    onTogglePush,
    onToggleTelegram,
    onToggleWeb,
    isPushEnabled,
    isTelegramEnabled,
    isWebEnabled,
    title,
    description,
}) => {
    const dispatch = useDispatch();

    const handlePushToggle = () => {
        onTogglePush && dispatch(onTogglePush());
    };

    return (
        <div className={styles['notificationBlock']}>
            <div className={styles['notificationBlockTextContainer']}>
                <h6 className={styles['notificationBlockTitle']}>{title}</h6>
                <p className={styles['notificationBlockText']}>{description}</p>
            </div>
            <div className={styles['notificationBlockBtnsContainer']}>
                {!onTogglePush && onToggleTelegram && (
                    <button
                        onClick={onToggleTelegram}
                        className={`${styles['notificationBlockBtn']} ${isTelegramEnabled ? styles['notificationBlockBtn_active'] : ''}`}
                    >
                        <Svg
                            id={telegramIcon}
                            className={styles['notificationBlockBtn__icon']}
                        />
                    </button>
                )}
                {onTogglePush && (
                    <button
                        onClick={handlePushToggle}
                        className={`${styles['notificationBlockBtn']} ${isPushEnabled ? styles['notificationBlockBtn_active'] : ''} `}
                    >
                        <Svg
                            id={webIcon}
                            className={styles['notificationBlockBtn__icon']}
                        />
                    </button>
                )}
                {!onTogglePush && onToggleWeb && (
                    <button
                        onClick={onToggleWeb}
                        className={`${styles['notificationBlockBtn']} ${isWebEnabled && styles['notificationBlockBtn_active']} `}
                    >
                        <Svg
                            id={webIcon}
                            className={styles['notificationBlockBtn__icon']}
                        />
                    </button>
                )}
            </div>
        </div>
    );
};

export default NotificationsButtonBox;
