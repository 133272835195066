import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSocket } from '../../../../hooks/useSocket';
import {
    getCEXOutflows,
    requestCEXOutflows,
} from '../../../../redux/action/cexOurflow';
import FiltersBlock from '../../../global/FiltersBlock';
import DataLoader from '../../../layout/DataLoader/DataLoader';
import CEXOutflowsListItem from './CEXOutflowsListItem';

import {
    authConfig,
    fetchRequest,
    reportUrl,
} from '../../../../redux/action/fetchTools';
import { setAutoRefresh } from '../../../../redux/action/filters';
import ApplyPopup from '../../../layout/ApplyPopup';
import styles from './styles.module.scss';

const CEXOutflowsList = () => {
    const data = useSelector((state) => state.cexOutflows.data);
    const loading = useSelector((state) => state.cexOutflows.getLoading);
    const autoRefresh = useSelector((state) => state.filters.autoRefresh);
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const [isReportSended, setIsReportSended] = useState(false);

    const onSuccessFilterApply = useCallback(requestCEXOutflows, []);

    const onSendReportMessage = useCallback(
        async (Item) => {
            const reqBody = {
                kind: `cex-outflow`,
                user: user,
                object: Item,
            };

            try {
                const res = await fetchRequest(
                    reportUrl,
                    'POST',
                    reqBody,
                    authConfig()
                );

                if (res.status === 200) {
                    setIsReportSended(true);
                }
            } catch (error) {
                console.log(error);
            }
        },
        [user]
    );

    useEffect(() => dispatch(setAutoRefresh()), [dispatch]);

    useSocket(
        useCallback((payload) => dispatch(getCEXOutflows(payload)), [dispatch]),
        useCallback(() => dispatch(requestCEXOutflows()), [dispatch]),
        'cex_outflow',
        autoRefresh
    );

    return (
        <div className={styles['CEXOutflowsList']}>
            <FiltersBlock onSuccessFilterApply={onSuccessFilterApply} />
            <ApplyPopup
                isOpened={isReportSended}
                onClose={() => setIsReportSended(false)}
                text="Жалоба отправлена"
            />
            {data.length > 0 ? (
                <div className={styles['CEXOutflowsList__container']}>
                    {data.map((item, index) => (
                        <CEXOutflowsListItem
                            key={index}
                            {...item}
                            Item={item}
                            onSendReportMessage={onSendReportMessage}
                        />
                    ))}
                </div>
            ) : (
                <DataLoader loading={loading} isEmpty={!data.length} />
            )}
        </div>
    );
};

export default CEXOutflowsList;
