import { useCallback, useEffect, useState } from 'react';
import switchIcon from '../../../../assets/icons/sniper/switch.svg';
import styles from './styles.module.scss';

const InfoBlock = ({
    side: initialSide,
    tokenName,
    amountOut,
    amountIn: initialAmountIn,
    slippage,
    tvl,
}) => {
    const [side, setSide] = useState(initialSide);
    const [amountIn, setAmountIn] = useState(initialAmountIn);
    const [avgPrice, setAvgPrice] = useState(0);

    useEffect(() => {
        if (amountOut && amountIn) {
            setAvgPrice(
                side === 'buy' ? amountOut / amountIn : amountIn / amountOut
            );
        }
    }, [amountOut, amountIn, side]);

    const handleSwitch = useCallback(() => {
        setAmountIn(() => {
            const newAmountIn =
                side === 'buy' ? amountOut * avgPrice : amountOut / avgPrice;
            return newAmountIn;
        });
        setSide((prevSide) => (prevSide === 'buy' ? 'sell' : 'buy'));
    }, [amountOut]);

    return (
        <div className={styles.infoBlock}>
            <div className={styles.infoBlockInner}>
                <span className={styles.subTitle}>
                    1{' '}
                    {(side === 'sell' ? tokenName : 'usdt').toLocaleUpperCase()}{' '}
                    ≈ {avgPrice.toFixed(4)}{' '}
                    {(side === 'buy' ? tokenName : 'usdt').toLocaleUpperCase()}
                </span>
                <button className={styles.switchBtn} onClick={handleSwitch}>
                    <img
                        src={switchIcon}
                        alt="switch view"
                        className={styles.switchIcon}
                    />
                </button>
            </div>

            <span className={styles.subTitle}>tvl = {tvl.toFixed(4)} </span>

            <span className={styles.subTitle}>
                min ≈ {(amountOut - (amountOut * slippage) / 100).toFixed(4)}{' '}
                {(initialSide === 'buy'
                    ? tokenName
                    : 'usdt'
                ).toLocaleUpperCase()}
            </span>
        </div>
    );
};

export default InfoBlock;
