import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { logOutIcon } from '../../../../../assets/svg';
import { logOut } from '../../../../../redux/action/auth';
import { loginPagePath } from '../../../../../router/path';
import Svg from '../../../../layout/Svg/Svg';
import { socials } from '../config';
import Icon from '../../../../../assets/icons/sniper/Icon.png';


import styles from './styles.module.scss';

const SideBarFooter = ({ isMobile, onCloseBurger, isCompact }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state) => state.auth.token);

    const onToggleAuth = () => {
        const clb = () => {
            navigate(loginPagePath);
            if (isMobile) onCloseBurger();
        };

        if (token) {
            dispatch(logOut(clb));
        } else {
            navigate(loginPagePath);
            if (isMobile) onCloseBurger();
        }
    };

    if (isCompact) {
        return (
            <div className={`${styles['sideBarFooter']} ${styles['sideBarFooter_compact']}`}>
                <img 
                src={Icon}
                alt="Icon"
                width="45"
                height="45"
            />
            </div>
        );
    }

    return (
        <><div className={styles['sideBarFooter']}>
            <div className={styles['sideBarFooter__rectangle']} />
        </div><div className={styles['sideBarFooter']}>

                <button
                    className={styles['sideBarFooter__logoutBtn']}
                    onClick={onToggleAuth}
                >
                    <Svg
                        className={styles['sideBarFooter__logoutIcon']}
                        id={logOutIcon} />
                    <span className={styles['sideBarFooter__logoutText']}>
                        {token ? 'Logout' : 'Log In'}
                    </span>
                </button>
            </div></>
    );
};

export default SideBarFooter;
