import React from 'react';
import styles from '../SubscriptionBlock.module.scss';

const CurrentSection = ({ name, price }) => {
    return (
        <div className={styles['subscriptionBlock__currentSection']}>
            <span>{name}</span>
            <span className={styles['subscriptionBlock__price']}>{price}$</span>
        </div>
    );
};

export default CurrentSection;
