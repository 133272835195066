import React from 'react';
import PropTypes from 'prop-types';
import styles from './line.module.scss';

const ProgressLine = ({ totalItems, activeIndex }) => {
    const height = totalItems * 25;
    const lineOffset = 13;
    const [currentIndex, setCurrentIndex] = React.useState(activeIndex);
    const [linePosition, setLinePosition] = React.useState(activeIndex * 25 + 14);

    React.useEffect(() => {
        if (currentIndex === activeIndex) return;

        const direction = activeIndex > currentIndex ? 1 : -1;
        const nextIndex = currentIndex + direction;
        const startPosition = currentIndex * 25 + 14;
        const targetPosition = nextIndex * 25 + 14;
        const duration = 100;
        const startTime = performance.now();

        const animate = (currentTime) => {
            const elapsed = currentTime - startTime;
            const progress = Math.min(elapsed / duration, 1);

            const currentPosition = startPosition + (targetPosition - startPosition) * progress;
            setLinePosition(currentPosition);

            if (progress === 1) {
                setCurrentIndex(nextIndex);
            } else {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);
    }, [currentIndex, activeIndex]);



    return (
        <div className={styles.progressLine}>
            <svg width="25" height={height} viewBox={`0 0 25 ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* Dark base line */}
                <line 
                className={styles.progressLine__base}
                x1="12.5" 
                y1="14" 
                x2="12.5" 
                y2={height - 14} 
                stroke="#191C2B"
                strokeWidth="0.7"
            />
                
                <defs>
                    <linearGradient 
                        id="activeLineGradient" 
                        gradientUnits="userSpaceOnUse"
                        x1="12.5" 
                        y1={linePosition - lineOffset}  // Используем linePosition вместо activeIndex
                        x2="12.5" 
                        y2={linePosition + lineOffset}  // Используем linePosition вместо activeIndex
                    >
                        <stop offset="0" stopColor="#14141E" stopOpacity="0" />
                        <stop offset="0.2" stopColor="#5E76EC" stopOpacity="1" />
                        <stop offset="0.8" stopColor="#5E76EC" stopOpacity="1" />
                        <stop offset="1" stopColor="#14141E" stopOpacity="0" />
                    </linearGradient>
                </defs>

                <line 
                    className={styles.progressLine__active}
                    x1="12.5" 
                    y1={linePosition - lineOffset} 
                    x2="12.5" 
                    y2={linePosition + lineOffset} 
                    stroke="url(#activeLineGradient)"
                    strokeWidth="0.7"
                    strokeLinecap="round"
                />

                {/* Points */}
                {Array(totalItems).fill(null).map((_, index) => (
    <g key={index}>
        {index === activeIndex ? (
            <>
                <circle
                    cx="12.5244"
                    cy={index * 25 + 14}
                    r="5.94336"
                    fill="#5B8CF4"
                    fillOpacity="0.08"
                />
                <g filter="url(#filter0_f_1409_79035)">
                    <circle
                        cx="12.5242"
                        cy={index * 25 + 14}
                        r="4.75469"
                        fill="#5B8CF4"
                    />
                </g>
                <circle
                    cx="12.525"
                    cy={index * 25 + 14}
                    r="3.56602"
                    fill="#899CFF"
                />
            </>
        ) : (
            <>
                <circle
                    cx="12.5244"
                    cy={index * 25 + 14}
                    r="5.94336"
                    fill="#191C2B"
                />
                <circle
                    cx="12.5241"
                    cy={index * 25 + 14}
                    r="3.39621"
                    fill="#515180"
                />
            </>
        )}
    </g>
))}

<defs>
    <filter
        id="filter0_f_1409_79035"
        x="0"
        y="0"
        width="100%"
        height="100%"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
    >
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feGaussianBlur stdDeviation="3.16979" result="effect1_foregroundBlur_1409_79035"/>
    </filter>
</defs>

                {/* Filters */}
                <defs>
                    <filter
                        id="filter0_f_1409_79035"
                        x="1.42995"
                        y="23.9185"
                        width="22.1884"
                        height="22.1884"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feGaussianBlur stdDeviation="3.16979" result="effect1_foregroundBlur_1409_79035"/>
                    </filter>
                </defs>
            </svg>
        </div>
    );
};

ProgressLine.propTypes = {
    totalItems: PropTypes.number.isRequired,
    activeIndex: PropTypes.number.isRequired
};


export default ProgressLine;
