import React from 'react';
import styles from './styles.module.scss';
import TrackerPageHeader from './TrackerPageHeader';
import TrackerPageList from './TrackerPageList';

const TrackerPageBlock = () => {
    return (
        <div className={styles['TrackerBlock']}>
            <TrackerPageHeader />
            <TrackerPageList />
        </div>
    );
};

export default TrackerPageBlock;
