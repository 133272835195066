import { useEffect, useRef } from 'react';

export const useSniperSocket = (
    requestMessage,
    onMessageReceived,
    onLoadingStart,
    onLoadingError
) => {
    const url = process.env.REACT_APP_BASE_URL;
    const socketRef = useRef(null);

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = new WebSocket(`wss://${url}ws`);

            const socket = socketRef.current;

            socket.addEventListener('open', () => {
                console.log('WebSocket connection opened');
                onLoadingStart();
                socket.send(JSON.stringify(requestMessage));
            });

            socket.addEventListener('message', (event) => {
                const responseData = JSON.parse(event.data);

                if (responseData.data) {
                    onMessageReceived(responseData.data);
                }

                if (responseData.id === -1) {
                    window.location.reload();
                }
                if (responseData.error) {
                    onLoadingError(responseData.error);
                }
            });

            socket.addEventListener('close', (event) => {
                console.log('WebSocket connection closed:', event);
            });

            socket.addEventListener('error', (event) => {
                console.error('WebSocket error:', event);
                onLoadingError(event);
            });
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
                socketRef.current = null;
            }
        };
    }, [
        requestMessage,
        onMessageReceived,
        onLoadingStart,
        onLoadingError,
        url,
    ]);
};
