import React from 'react';
import Svg from '../../../../layout/Svg/Svg';
import { crossIcon } from '../../../../../assets/svg';
import styles from './styles.module.scss';

const SubscriptionModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
    
    return (
        <div className={styles['sideBarLinks__modal']}>
            <p>Для доступа к этому разделу необходима подписка.</p>
            <button
                onClick={onClose}
                className={styles['sideBarLinks__modalClose']}
            >
                <Svg
                    className={styles['sideBarLinks__modalCloseIcon']}
                    id={crossIcon}
                />
            </button>
        </div>
    );
};

export default SubscriptionModal;
