import React from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import TrackerPageBlock from '../../components/TrackerPage/TrackerPageBlock';

const TrackerPage = () => {
    return (
        <Scrollbar
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <div className={'gradientBg'}>
                <div className="container">
                    <TrackerPageBlock />
                </div>
            </div>
        </Scrollbar>
    );
};

export default TrackerPage;
