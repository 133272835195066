import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sniperGasIcon from '../../../assets/icons/sniper/gas.svg';
import sniperSlippageIcon from '../../../assets/icons/sniper/slippage.svg';
import sniperWalletIcon from '../../../assets/icons/sniper/wallet.svg';
import { sniperSideIcon } from '../../../assets/svg';
import { sniperDialogPagePath } from '../../../router/path';
import ExtendedInput from '../../layout/ExtendedInput';
import MainBtn from '../../layout/MainBtn/MainBtn';
import Svg from '../../layout/Svg/Svg';
import BlockchainPicker from './BlockchainPicker';
import InfoBlock from './InfoBlock';
import styles from './styles.module.scss';
import TokenBlock from './TokenBlock';

const WidgetBlock = ({
    amountChangeHandler,
    blockchainChangeHandler,
    tokenChangeHandler,
    side,
    sideChangeHandler,
    token,
    tokensList,
    blockchain,
    blockchainsList,
    amountIn,
    amountOut,
    userWallet,
    id,
    tokenAddress,
    initialGasPrice,
    tvl,
}) => {
    const navigate = useNavigate();

    const [wallet, setWallet] = useState(userWallet || '');
    const [slippage, setSlippage] = useState(0.5);
    const [gasPrice, setGasPrice] = useState(0);
    const [amount, setAmount] = useState(amountIn);

    useEffect(() => {
        if (initialGasPrice) {
            setGasPrice(initialGasPrice);
        }
    }, [initialGasPrice]);

    useEffect(() => {
        if (!isNaN(amount)) {
            amountChangeHandler(amount);
        }
    }, [amount, amountChangeHandler]);

    const onWalletChange = (e) => {
        setWallet(e.target.value);
    };

    const onSlippageChange = (e) => {
        setSlippage(Number(e.target.value));
    };

    const onGasPriceChange = (e) => {
        setGasPrice(Number(e.target.value));
    };

    const onTokenAmountChange = (e) => {
        setAmount(Number(e.target.value));
    };

    const onSubmitTransaction = () => {
        navigate(sniperDialogPagePath, {
            state: {
                swapParams: {
                    event: 'swap',
                    blockchain,
                    id,
                    token: token?.name,
                    side,
                    amount: amount || 0,
                    slippage: slippage || 0,
                    gas_price: gasPrice || 0,
                    token_address: tokenAddress,
                    wallet,
                },
            },
        });
    };

    const slippageOptions = [0.1, 0.5, 1];

    return (
        <section className={styles['widgetBlock']}>
            <BlockchainPicker
                blockchain={blockchain}
                blockchainsList={blockchainsList}
                blockchainChangeHandler={blockchainChangeHandler}
            />
            <div className={styles.tokenBox}>
                {['sell', 'buy'].includes(side) ? (
                    <>
                        <TokenBlock
                            isActive={true}
                            type={'sell'}
                            amount={amount}
                            tokenIcon={
                                side === 'sell' ? token?.name || '' : 'usdt'
                            }
                            tokenName={
                                side === 'sell' ? token?.name || '' : 'USDT'
                            }
                            onAmountChange={onTokenAmountChange}
                        />
                        <button
                            className={styles.sideBtn}
                            onClick={() => {
                                side === 'sell'
                                    ? sideChangeHandler('buy')
                                    : sideChangeHandler('sell');
                            }}
                        >
                            <Svg
                                className={styles.sideIcon}
                                id={sniperSideIcon}
                            />
                        </button>
                        <TokenBlock
                            isActive={false}
                            type={'buy'}
                            amount={amountOut}
                            tokenIcon={
                                side === 'buy' ? token?.name || '' : 'usdt'
                            }
                            tokenName={
                                side === 'buy' ? token?.name || '' : 'USDT'
                            }
                        />
                    </>
                ) : (
                    <>Invalid transaction side</>
                )}
                <InfoBlock
                    side={side}
                    tokenName={token?.name || ''}
                    amountIn={amountIn}
                    amountOut={amountOut}
                    slippage={slippage}
                    tvl={tvl}
                />
            </div>

            <h3 className={styles.subTitle}>Set slippage</h3>
            <ExtendedInput
                type="number"
                icon={sniperSlippageIcon}
                onChange={onSlippageChange}
                value={slippage}
                measure="%"
            />

            <div className={styles.slippageBtns}>
                {slippageOptions.map((option) => (
                    <MainBtn key={option} onClick={() => setSlippage(option)}>
                        {option}%
                    </MainBtn>
                ))}
            </div>

            <h3 className={styles.subTitle}>
                {blockchain === 'solana' ? 'Set jito tip' : 'Set gas price'}
            </h3>
            <ExtendedInput
                measure={blockchain === 'solana' ? 'SOL' : 'GWEI'}
                type="number"
                icon={sniperGasIcon}
                value={gasPrice}
                onChange={onGasPriceChange}
            />

            <h3 className={styles.subTitle}>Set your wallet</h3>
            <ExtendedInput
                icon={sniperWalletIcon}
                value={wallet}
                onChange={onWalletChange}
                type="text"
            />

            <MainBtn
                onClick={onSubmitTransaction}
                disabled={
                    !wallet || amount <= 0 || slippage <= 0 || gasPrice <= 0
                }
            >
                Confirm transaction
            </MainBtn>
        </section>
    );
};

export default WidgetBlock;
