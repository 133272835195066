import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './ArbitragePageHeader.module.scss';
import { arbitrageTypes } from '../../../constants';

const ArbitragePageHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentHash = location.hash.slice(1);

    const renderNavButton = (text, route, hasDelimiter = true) => (
        <>
            <button
                className={`${styles['arbitrageHeader__navButton']} ${
                    currentHash === route ? styles['arbitrageHeader__navButton_active'] : ''
                }`}
                onClick={() => navigate(`/arbitrage#${route}`)}
            >
                {text}
            </button>
            {hasDelimiter && <div className={styles['arbitrageHeader__divider']} />}
        </>
    );

    return (
        <div className={styles['arbitrageHeader']}>
            <h5 className={'pageRouteTitle'}>Arbitrage</h5>
            <nav className={styles['arbitrageHeader__panel']}>
                {renderNavButton('Cex - Cex', arbitrageTypes.cexToCex)}
                {renderNavButton('Cex - Dex', arbitrageTypes.cexToDex)}
                {renderNavButton('Dex - Dex', arbitrageTypes.dexToDex)}
                {renderNavButton('Arbitrage Free', arbitrageTypes.free, false)}
            </nav>
        </div>
    );
};

export default ArbitragePageHeader;
