import React from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import ArbitragePageHeader from '../../components/ArbitragePage/ArbitragePageHeader/ArbitragePageHeader';
import ArbitragePageList from '../../components/ArbitragePage/ArbitragePageList/ArbitragePageList';
import { requestClosedArbitrage } from '../../redux/action/arbitrage';

const ClosedArbitragePage = () => {
    return (
        <Scrollbar
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <div className={'gradientBg'}>
                <div className="container">
                    <ArbitragePageHeader arbitrageType="Closed Arbitrage" />
                    <ArbitragePageList
                        arbitrageType="Closed Arbitrage"
                        socketSubscrition="closed-arb"
                        restApiFunction={requestClosedArbitrage}
                    />
                </div>
            </div>
        </Scrollbar>
    );
};

export default ClosedArbitragePage;
