export const useSubscriptionHandler = (userSubscriptions, setIsModalOpen) => {
    const hasSubscription = (subscription) => {
        return userSubscriptions.includes(subscription);
    };

    const handleLinkClick = (link) => {
        const sectionName = link.path?.slice(1);

        const closedSectionConditions = [
            {
                condition: sectionName === 'cexOutflows',
                subscription: 'cex_outflows',
            },
            {
                condition: link.title?.includes('CEX — CEX Closed Arbitrage'),
                subscription: 'closed_cex-cex',
            },
            {
                condition: link.title?.includes('CEX — DEX Closed Arbitrage'),
                subscription: 'closed_cex-dex',
            },
            {
                condition: link.href === 'funding-arb',
                subscription: 'funding',
            },
            {
                condition: link.href === 'futures-arb',
                subscription: 'funding',
            },
            {
                condition: sectionName === 'sniper',
                subscription: 'sniper',
            },
            {
                condition: link.href === 'cex-tracker',
                subscription: 'cex_tracker',
            },
            {
                condition: link.href === 'dex-tracker',
                subscription: 'dex_tracker',
            },
        ];

        for (let condition of closedSectionConditions) {
            if (
                condition.condition &&
                !hasSubscription(condition.subscription)
            ) {
                setIsModalOpen(true);
                return false;
            }
        }

        if (
            link.isPrivate &&
            !link.title?.includes('CEX — CEX Closed Arbitrage') &&
            !link.title?.includes('CEX — DEX Closed Arbitrage') &&
            link.href !== 'cex-tracker' &&
            link.href !== 'dex-tracker' &&
            link.href !== 'sniper' &&
            link.href !== 'funding-arb' &&
            link.href !== 'futures-arb' &&
            !hasSubscription(link.href || sectionName)
        ) {
            setIsModalOpen(true);
            return false;
        }

        return true;
    };

    return { handleLinkClick };
};
