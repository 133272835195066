import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';

import NewsItemModal from '../../components/NewsPage/NewsItemModal/NewsItemModal';
import NewsList from '../../components/NewsPage/NewsList/./NewsList';

function NewsPage() {
    const data = useSelector((state) => state.news.data);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const loading = useSelector((state) => state.news.getLoading);

    const selectedItem = data.find((item) => item.id === selectedItemId);
    const openItemModal = (id) => setSelectedItemId(id);
    const closeItemModal = () => setSelectedItemId(null);

    return (
        <Scrollbar
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <div className={'gradientBg'}>
                <NewsList
                    loading={loading}
                    data={data}
                    onClick={openItemModal}
                />
                <NewsItemModal item={selectedItem} onClose={closeItemModal} />
            </div>
        </Scrollbar>
    );
}

export default NewsPage;
