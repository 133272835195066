import React from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import CEXOutflowsPageBlock from '../../components/CEXOutflowsPage/CEXOutflowsPageBlock';

const CEXOutflowsPage = () => {
    return (
        <Scrollbar
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <div className={'gradientBg'}>
                <div className="container">
                    <CEXOutflowsPageBlock />
                </div>
            </div>
        </Scrollbar>
    );
};

export default CEXOutflowsPage;
