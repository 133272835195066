import React, { useState } from 'react';
import { crossIcon } from '../../../../../assets/svg';
import MainBtn from '../../../../layout/MainBtn/MainBtn';
import MainInput from '../../../../layout/MainInput/MainInput';
import Svg from '../../../../layout/Svg/Svg';
import styles from './styles.module.scss';

const BalanceModal = ({
    withdrawChain,
    withdrawToken,
    userId,
    onCloseModal,
}) => {
    const [withdrawArrdess, setWithdrawAddress] = useState('');
    const [withdrawAmount, setWithdrawAmount] = useState('');

    return (
        <div className={styles['balanceModal']}>
            <div className={styles['balanceModal__header']}>
                <h3>Вывод</h3>
                <button
                    onClick={onCloseModal}
                    className={styles['balanceModal__closeBtn']}
                >
                    <Svg
                        className={styles['balanceModal__closeIcon']}
                        id={crossIcon}
                    />
                </button>
            </div>
            <div className={styles['balanceModal__box']}>
                <div className={styles['balanceModal__intputBox']}>
                    <span>{withdrawChain}</span>
                    <MainInput
                        onChange={(e) => {
                            setWithdrawAddress(e.target.value);
                        }}
                        value={withdrawArrdess}
                        placeholder="Адрес вывода"
                    />
                </div>
                <div className={styles['balanceModal__intputBox']}>
                    <span>{withdrawToken}</span>
                    <MainInput
                        onChange={(e) => {
                            setWithdrawAmount(e.target.value);
                        }}
                        value={withdrawAmount}
                        type="number"
                        placeholder="Сумма вывода"
                    />
                </div>
                <MainBtn
                    onClick={() => {
                        console.log('withdraw req:', {
                            id: userId,
                            amout: withdrawAmount,
                            blockchain: withdrawChain,
                            address: withdrawArrdess,
                        });
                    }}
                >
                    Подтвердить вывод
                </MainBtn>
            </div>
        </div>
    );
};

export default BalanceModal;
