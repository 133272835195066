import React, { memo } from 'react';
import {
    blacklistIcon,
    filtersIcon,
    hideneyeIcon,
    refreshIcon,
} from '../../../../assets/svg';
import Svg from '../../../layout/Svg/Svg';
import styles from './styles.module.scss';

const OptionsBlock = memo(({
    openBlackListModal,
    openFilterModal,
    openHiddenItemsModal,
    toggleAutoRefresh,
    autoRefresh,
    isSubscribed,
    isBlackListModalOpened,
    isHiddenItemsModalOpened,
    isFilterModalOpened
}) => {
    return (
        <div className={styles['optionsBlock']}>
            <div className={styles['optionsBlock__leftButtons']}>
                <button
                    className={`${styles['optionsBlock__btn']} ${isBlackListModalOpened ? styles['optionsBlock__btn_active'] : ''}`}
                    onClick={openBlackListModal}
                >
                    <Svg className={styles['optionsBlock__icon']} id={blacklistIcon} />
                    Blacklist
                </button>

                <button
                    className={`${styles['optionsBlock__btn']} ${styles['optionsBlock__eyeBtn_desk']} ${isHiddenItemsModalOpened ? styles['optionsBlock__btn_active'] : ''}`}
                    onClick={openHiddenItemsModal}
                >
                    <Svg className={styles['optionsBlock__icon']} id={hideneyeIcon} />
                    Hidden
                </button>
            </div>

            <div className={styles['optionsBlock__rightButtons']}>
                {isSubscribed && (
                    <button
                        onClick={openFilterModal}
                        className={`${styles['optionsBlock__btn']} ${isFilterModalOpened ? styles['optionsBlock__btn_active'] : ''}`}
                    >
                        <Svg className={styles['optionsBlock__icon']} id={filtersIcon} />
                        Filters
                    </button>
                )}

                <button
                    className={`${styles['optionsBlock__btn']} ${autoRefresh ? styles['optionsBlock__btn_active'] : ''}`}
                    onClick={toggleAutoRefresh}
                    data-refresh="true"
                >
                    <Svg className={styles['optionsBlock__icon']} id={refreshIcon} />
                    Auto refresh
                </button>
            </div>
        </div>
    );
});

export default OptionsBlock;
