import React from 'react';
import styles from './Divider.module.scss';

const Divider = () => (
    <svg 
        width="100%" 
        height="2" 
        viewBox="0 0 582 2" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        className={styles.divider}
    >
        <g filter="url(#filter0_d_1288_17178)">
            <path d="M1 1H580.5" stroke="url(#paint0_radial_1288_17178)" strokeOpacity="0.42" shapeRendering="crispEdges"/>
        </g>
        <defs>
            <filter id="filter0_d_1288_17178" x="0" y="0" width="581.5" height="3" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="0.5"/>
                <feGaussianBlur stdDeviation="0.5"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0.12549 0 0 0 0 0.137255 0 0 0 0 0.215686 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1288_17178"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1288_17178" result="shape"/>
            </filter>
            <radialGradient id="paint0_radial_1288_17178" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(290.75 1) rotate(90) scale(71 289.75)">
                <stop stopColor="#9FA7DE"/>
                <stop offset="1"/>
            </radialGradient>
        </defs>
    </svg>
);

export default Divider;
