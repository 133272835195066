import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom';

import AuthBlock from '../../components/global/AuthBlock/AuthBlock';
import { signup } from '../../redux/action/auth';
import { mainPagePath } from '../../router/path';
import { setLSItem } from '../../utils/functions/localStorage';
import { lsProps } from '../../utils/lsProps';
import { formProps } from './formProps';

function SignUpPage() {
    const loading = useSelector((state) => state.auth.signupLoading);
    const error = useSelector((state) => state.auth.signupError);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = (formData) => {
        const navigateToHome = () => navigate(mainPagePath);
        setLSItem(lsProps.isRegistered, true);
        dispatch(signup(formData, navigateToHome));
    };

    return (
        <Scrollbar
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <AuthBlock
                {...formProps()}
                error={error}
                onSubmit={onSubmit}
                loading={loading}
            />
        </Scrollbar>
    );
}

export default SignUpPage;
