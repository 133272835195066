import React from 'react';
import styles from './styles.module.scss';

const CheckboxFilter = ({ options, onToggleCheckBox, formData, name }) => {
    return (
        <div className={styles['checkboxesList']}>
            {options?.map(({ title, value }, optionIndex) => (
                <div key={optionIndex}>
                    <input
                        name={name}
                        onChange={() => onToggleCheckBox(name, value)}
                        value={value}
                        id={`${title}-${value}`}
                        type="checkbox"
                        checked={formData[name]?.includes(value)}
                        className={styles['checkbox__input']}
                    />
                    <label
                        htmlFor={`${title}-${value}`}
                        className={styles['checkbox__label']}
                    >
                        {title}
                    </label>
                </div>
            ))}
        </div>
    );
};

export default CheckboxFilter;
