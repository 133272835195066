import React, { useCallback, useEffect, useState } from 'react';
import DataLoader from '../../../layout/DataLoader/DataLoader';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { trackerTypes } from '../../../../constants';
import { useSocket } from '../../../../hooks/useSocket';
import {
    authConfig,
    fetchRequest,
    reportUrl,
} from '../../../../redux/action/fetchTools';
import {
    changeFilters,
    setAutoRefresh,
} from '../../../../redux/action/filters';
import {
    getCexTracker,
    getDexTracker,
    requestCexTracker,
    requestDexTracker,
} from '../../../../redux/action/tracker';
import FiltersBlock from '../../../global/FiltersBlock';
import ApplyPopup from '../../../layout/ApplyPopup';
import TrackerPageListItem from './TrackerPageListItem';
import styles from './styles.module.scss';

const TrackerPageList = () => {
    const dispatch = useDispatch();
    const { hash } = useLocation();
    const pageType = hash.slice(1);
    const user = useSelector((state) => state.auth.user);
    const filters = useSelector((state) => state.filters.filters);

    const autoRefresh = useSelector((state) => state.filters.autoRefresh);
    const cexTrackerData = useSelector((state) => state.tracker.cexTrackerData);
    const dexTrackerData = useSelector((state) => state.tracker.dexTrackerData);
    const cexTrackerLoading = useSelector(
        (state) => state.tracker.getCexTrackerLoading
    );
    const dexTrackerLoading = useSelector(
        (state) => state.tracker.getDexTrackerLoading
    );

    const [isReportSended, setIsReportSended] = useState(false);

    const data =
        pageType === trackerTypes.CEX
            ? cexTrackerData
            : pageType === trackerTypes.DEX
              ? dexTrackerData
              : null;
    const loading =
        pageType === trackerTypes.CEX
            ? cexTrackerLoading
            : pageType === trackerTypes.DEX
              ? dexTrackerLoading
              : null;

    useEffect(() => dispatch(setAutoRefresh()), [dispatch, hash]);

    useSocket(
        useCallback(
            (payload) =>
                dispatch(
                    hash === '#cex-tracker'
                        ? getCexTracker(payload)
                        : getDexTracker(payload)
                ),
            [dispatch, hash]
        ),
        useCallback(
            () =>
                dispatch(
                    hash === '#cex-tracker'
                        ? requestCexTracker()
                        : requestDexTracker()
                ),
            [dispatch, hash]
        ),
        hash === '#cex-tracker' ? 'cex_tracker' : 'dex_tracker',
        autoRefresh
    );

    const onSuccessFilterApply = useCallback(
        hash === '#cex-tracker' ? requestCexTracker : requestDexTracker,
        [requestCexTracker, requestDexTracker, hash]
    );

    const onSendReportMessage = useCallback(
        async (Item) => {
            const reqBody = {
                kind: `${pageType}`,
                user: user,
                object: Item,
            };

            try {
                const res = await fetchRequest(
                    reportUrl,
                    'POST',
                    reqBody,
                    authConfig()
                );

                if (res.status === 200) {
                    setIsReportSended(true);
                }
            } catch (error) {
                console.log(error);
            }
        },
        [pageType, user]
    );

    const onAddToBlackList = useCallback(
        (symbol) => {
            dispatch(
                changeFilters({ blacklist: [...filters.blacklist, symbol] })
            );
        },
        [dispatch, filters.blacklist]
    );

    const onAddToHidden = useCallback(
        (item) => {
            const newItem = {
                symbol: item.token,
                ex1: item.exchange,
                created_at: Date.now(),
            };
            const oldData = filters.hidden || [];
            if (
                !oldData.find(
                    (oldItem) =>
                        oldItem.symbol === item.token &&
                        oldItem.ex1 === item.exchange
                )
            ) {
                dispatch(changeFilters({ hidden: [...oldData, newItem] }));
            }
        },
        [dispatch, filters.hidden]
    );

    return (
        <div className={styles['TrackerList']}>
            <FiltersBlock onSuccessFilterApply={onSuccessFilterApply} />
            <ApplyPopup
                isOpened={isReportSended}
                onClose={() => setIsReportSended(false)}
                text="Жалоба отправлена"
            />
            <div
                className={`${styles['TrackerList__container']} ${(loading || !data.length) && styles['TrackerList__container_noData']}`}
            >
                {data.length > 0 ? (
                    data.map((item, index) => (
                        <TrackerPageListItem
                            type={hash.slice(1)}
                            key={item.id}
                            {...item}
                            Item={item}
                            onSendReportMessage={onSendReportMessage}
                            onAddToBlackList={onAddToBlackList}
                            onAddToHidden={onAddToHidden}
                        />
                    ))
                ) : (
                    <DataLoader
                        loading={loading}
                        isEmpty={!loading && data?.length === 0}
                    />
                )}
            </div>
        </div>
    );
};

export default TrackerPageList;
