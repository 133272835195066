import React, { useEffect, useState } from 'react';
import SideBar from './SideBar/';

function Navbar() {
    const [burgerOpened, setBurgerOpened] = useState(true);
    const [isCompact, setIsCompact] = useState(false);

    useEffect(() => {
        if (burgerOpened) {
            if (isCompact) {
                document.body.classList.add('navbarCompactOpened');
                document.body.classList.remove('navbarFullOpened');
            } else {
                document.body.classList.add('navbarFullOpened');
                document.body.classList.remove('navbarCompactOpened');
            }
        } else {
            document.body.classList.remove('navbarCompactOpened', 'navbarFullOpened');
        }
    }, [burgerOpened, isCompact]);

    const onCloseBurger = () => setBurgerOpened(false);

    return (
        <SideBar
            burgerOpened={burgerOpened}
            onCloseBurger={onCloseBurger}
            isMobile={isCompact}
            isCompact={isCompact}
            onToggleCompact={() => setIsCompact(!isCompact)}
        />
    );
}

export default Navbar;
