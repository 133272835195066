import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { changeUserData } from '../../../redux/action/auth';
import { changeFilters, setAutoRefresh } from '../../../redux/action/filters';
import { getCreatedAt } from '../../../utils/functions/date';
import ApplyPopup from '../../layout/ApplyPopup';
import FilterModal from './FilterModal';
import NonVisibleItemsModal from './NonVisbleItemsModal';
import OptionsBlock from './OptionsBlock';

const FiltersBlock = memo(({ onSuccessFilterApply }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const noHashPageType = location.pathname
        .replace('/', '')
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase();
    const autoRefresh = useSelector((state) => state.filters.autoRefresh);
    const user = useSelector((state) => state.auth.user);
    const filters = useSelector((state) => state.filters.filters);
    const pageType = location.hash ? location.hash.slice(1) : noHashPageType;
    const isSubscribed = Boolean(user?.subscription);

    const [isBlackListModalOpened, setIsBlackListModalOpened] = useState(false);
    const [isHiddenItemsModalOpened, setIsHiddenItemsModalOpened] =
        useState(false);
    const [isFilterModalOpened, setIsFilterModalOpened] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const filterAppliedModalIntervalRef = useRef(null);

    const openBlackListModal = useCallback(
        () => setIsBlackListModalOpened(true),
        []
    );
    const closeBlackListModal = useCallback(
        () => setIsBlackListModalOpened(false),
        []
    );
    const openHiddenItemsModal = useCallback(
        () => setIsHiddenItemsModalOpened(true),
        []
    );
    const closeHiddenItemsModal = useCallback(
        () => setIsHiddenItemsModalOpened(false),
        []
    );
    const openFilterModal = useCallback(() => setIsFilterModalOpened(true), []);
    const closeFilterModal = useCallback((isCreated) => {
        setIsFilterModalOpened(false);
        if (isCreated && typeof isCreated == 'boolean')
            openFilterAppliedModal();
    }, []);
    const openFilterAppliedModal = useCallback(
        () => setIsFilterApplied(true),
        []
    );
    const closeFilterAppliedModal = useCallback(
        () => setIsFilterApplied(false),
        []
    );
    const toggleAutoRefresh = useCallback(
        () => dispatch(setAutoRefresh(true)),
        []
    );

    useEffect(() => {
        let interval = null;
        if (filters.hidden && filters.hidden_time) {
            interval = setInterval(
                () => {
                    const hidden = filters.hidden.filter((hidden) => {
                        const now = new Date();
                        const createdAt = getCreatedAt(hidden.created_at);
                        createdAt.setHours(createdAt.getHours() + 4);
                        createdAt.setMinutes(
                            createdAt.getMinutes() + filters.hidden
                        );
                        return createdAt > now;
                    });
                    if (hidden.length !== filters.hidden.length) {
                        dispatch(changeUserData({ hidden }));
                    }
                },
                filters.hidden_time * 60 * 1000
            );
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [filters, dispatch]);

    useEffect(() => {
        if (isFilterApplied) {
            filterAppliedModalIntervalRef.current = setTimeout(() => {
                closeFilterAppliedModal();
            }, 2000);
        } else {
            if (filterAppliedModalIntervalRef.current)
                clearInterval(filterAppliedModalIntervalRef.current);
        }
    }, [isFilterApplied]);

    const onRemoveBlackListItem = (item) => {
        dispatch(
            changeFilters({
                ...filters,
                blacklist: filters.blacklist?.filter(
                    (filterItem) => filterItem !== item
                ),
            })
        );
    };

    const buttonContainerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '36px 0 0 0',
        gap: '16px',
        bottom: 0,
      };
      
      const leftButtonStyle = {
        width: '321px',
        height: '40px',
        padding: '0 16px',
        gap: '6px',
        borderRadius: '16px',
        color: 'white',
        background: 'rgba(88, 113, 242, 0.08)',
        border: '1px solid rgba(88, 113, 242, 0.42)',
        fontSize: '16px',

      };

      const rightButtonStyle = {
        width: '299px',
        height: '40px',
        padding: '0 16px',
        gap: '6px',
        borderRadius: '16px',
        background: '#5871F2',
        color: 'white',
        border: 'none',
        fontSize: '16px',
      };
      

    const onRemoveHiddenItem = (item) => {
        dispatch(
            changeFilters({
                ...filters,
                hidden: filters.hidden?.filter(
                    (filterItem) => filterItem.symbol !== item
                ),
            })
        );
    };

    return (
        <>
            <NonVisibleItemsModal
                isOpened={isBlackListModalOpened}
                onClose={closeBlackListModal}
                title={'Blacklist'}
                items={filters.blacklist || []}
                onRemoveItem={onRemoveBlackListItem}
                buttons={
                    <div style={buttonContainerStyle}>
                        <button 
                            style={leftButtonStyle} 
                            onClick={() => dispatch(changeFilters({ ...filters, blacklist: [] }))}
                        >
                            Clear all
                        </button>                      
                        <button style={rightButtonStyle} onClick={closeBlackListModal}>Ok</button>
                      </div>
                  }
            />
            <NonVisibleItemsModal
                isOpened={isHiddenItemsModalOpened}
                onClose={closeHiddenItemsModal}
                title={'Hidden token'}
                items={filters.hidden?.map((item) => item.symbol) || []}
                onRemoveItem={onRemoveHiddenItem}
                buttons={
                    <div style={buttonContainerStyle}>
                        <button 
                            style={leftButtonStyle} 
                            onClick={() => dispatch(changeFilters({ ...filters, hidden: [] }))}
                        >
                            Clear all
                        </button>                      
                        <button style={rightButtonStyle} onClick={closeHiddenItemsModal}>Ok</button>
                    </div>
                  }
            />
            {user?.subscription && (
                <FilterModal
                    pageType={pageType}
                    show={isFilterModalOpened}
                    onClose={closeFilterModal}
                    onSuccessFilterApply={onSuccessFilterApply}
                />
            )}
            <ApplyPopup
                isOpened={isFilterApplied}
                onClose={closeFilterAppliedModal}
                text="Фильтр с заданными параметрами применен"
            />
            <OptionsBlock
                isSubscribed={isSubscribed}
                autoRefresh={autoRefresh}
                toggleAutoRefresh={toggleAutoRefresh}
                openBlackListModal={openBlackListModal}
                openFilterModal={openFilterModal}
                openHiddenItemsModal={openHiddenItemsModal}
                isBlackListModalOpened={isBlackListModalOpened}    
                isHiddenItemsModalOpened={isHiddenItemsModalOpened}
                isFilterModalOpened={isFilterModalOpened} 
            />
        </>
    );
});

export default FiltersBlock;
