import {
    GET_ARBITRAGE_ERROR,
    GET_ARBITRAGE_LOADING_START,
    GET_ARBITRAGE_SUCCESS,
} from '../types';

const initialState = {
    data: [],
    getLoading: false,
    getError: null,
};

export const arbitrageReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_ARBITRAGE_SUCCESS: {
            return {
                ...state,
                data: payload,
                getLoading: false,
            };
        }
        case GET_ARBITRAGE_LOADING_START: {
            return {
                ...state,
                getLoading: true,
                getError: null,
            };
        }
        case GET_ARBITRAGE_ERROR: {
            return {
                ...state,
                getError: payload,
                getLoading: false,
            };
        }
        default:
            return state;
    }
};
