import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import NavigationLinks from './NavigationLinks';
import SideBarHeader from './SideBarHeader';
import SocialLinks from './SocialLinks';
import SubscriptionModal from './SubscriptionModal';

import { useSubscriptionHandler } from '../hooks/useSubscriptionHandler';
import { navLinks } from '../config';
import styles from './styles.module.scss';

const SideBarLinks = ({
    onCloseBurger,
    isMobile,
    isCompact,
    onToggleCompact,
    openLink,
    setOpenLink,
}) => {
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    
    const user = useSelector((state) => state.auth.user);
    const userSubscriptions = user?.subscription
        ? user.subscription.split(',')
        : [];
    const { pathname, hash } = location;

    const setActiveNavLinkClass = (defaultClass, activeClass) => {
        return ({ isActive }) =>
            isActive ? `${defaultClass} ${activeClass}` : defaultClass;
    };

    const activeNavLinks = setActiveNavLinkClass(
        styles['sideBarLinks__link'],
        styles['sideBarLinks__link_active']
    );

    const allowedLinks = navLinks;

    const { handleLinkClick } = useSubscriptionHandler(
        userSubscriptions,
        setIsModalOpen
    );

    const toggleAccordion = (linkPath, index) => {
        if (isCompact) {
            onToggleCompact(false);
            setOpenLink(linkPath);
        } else {
            setOpenLink((prev) => (prev === linkPath ? null : linkPath));
        }
        setActiveItemIndex(index);
    };

    return (
        <>
            <SubscriptionModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
            <SideBarHeader isCompact={isCompact} />

            <div
                className={`
                ${styles['sideBarLinks__container']} 
                ${isCompact ? styles['sideBarLinks__container_compact'] : ''}`}
            >
                <NavigationLinks
                    allowedLinks={allowedLinks}
                    user={user}
                    pathname={pathname}
                    hash={hash}
                    isCompact={isCompact}
                    openLink={openLink}
                    handleLinkClick={handleLinkClick}
                    toggleAccordion={toggleAccordion}
                    onToggleCompact={onToggleCompact}
                    activeNavLinks={activeNavLinks}
                    onCloseBurger={onCloseBurger}
                    isMobile={isMobile}
                    navLinks={navLinks}
                    activeItemIndex={activeItemIndex}
                    setActiveItemIndex={setActiveItemIndex}
                />

                <SocialLinks isCompact={isCompact} />
                <b className={styles['sideBarLinks__linebtm']} />
            </div>
        </>
    );
};

export default SideBarLinks;
