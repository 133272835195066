import { combineReducers } from 'redux';
import { arbitrageReducer } from './arbitrageReducer';
import { authReducer } from './authReducer';
import { cexOutflowsReducer } from './cexOutflowsReducer';
import { filtersReducer } from './filtersReducer';
import { fundingReducer } from './fundingReducer';
import { newsReducer } from './newsReducer';
import { notificationsReducer } from './notificationReducer';
import { sniperReducer } from './sniperReducer';
import { trackerReducer } from './trackerReducer';

export default combineReducers({
    auth: authReducer,
    news: newsReducer,
    cexOutflows: cexOutflowsReducer,
    tracker: trackerReducer,
    arbitrage: arbitrageReducer,
    funding: fundingReducer,
    filters: filtersReducer,
    notifications: notificationsReducer,
    sniper: sniperReducer,
});
