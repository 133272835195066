import cn from 'classnames';
import React, { useLayoutEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';

const inputPadding = 8;
const suffixGap = 12;

const ExtendedInput = ({
    value,
    icon,
    className,
    measure,
    placeholder,
    onChange,
    ...props
}) => {
    const suffixRef = useRef(null);
    const iconRef = useRef(null);

    const [inputRightPadding, setInputRightPadding] = useState(0);
    const [inputLeftPadding, setInputLeftPadding] = useState(0);

    const [fakeValue, setFakeValue] = useState(value);

    useLayoutEffect(() => {
        setFakeValue(value);
    }, [value]);

    useLayoutEffect(() => {
        const suffixWidth = suffixRef.current?.offsetWidth;
        const iconWidth = iconRef.current?.offsetWidth;
        setInputRightPadding(
            measure && suffixWidth
                ? suffixWidth + (inputPadding + suffixGap)
                : inputPadding
        );
        setInputLeftPadding(
            icon && iconWidth
                ? iconWidth + (inputPadding + suffixGap)
                : inputPadding
        );
    }, [measure, icon]);

    return (
        <div className={styles.inputWrapper}>
            <input
                className={cn(styles.input, className)}
                style={{
                    padding: inputPadding,
                    paddingTop: inputPadding - 2,
                    paddingLeft: inputLeftPadding,
                    paddingRight: inputRightPadding,
                }}
                value={fakeValue}
                placeholder={placeholder}
                {...props}
                onChange={(e) => {
                    setFakeValue(e.target.value);
                    if (onChange) {
                        onChange(e);
                    }
                }}
            />
            <div
                className={styles.inputFakeValueWrapper}
                style={{ gap: suffixGap, padding: inputPadding }}
            >
                {icon && (
                    <img ref={iconRef} className={styles.icon} src={icon} />
                )}
                <span className={styles.inputFakeValue}>
                    {fakeValue !== undefined && fakeValue !== ''
                        ? fakeValue
                        : placeholder}
                </span>
                <span ref={suffixRef} className={styles.suffix}>
                    {measure}
                </span>
            </div>
        </div>
    );
};

export default ExtendedInput;
