import React from 'react';
import { emptyIcon } from '../../../assets/svg';
import TransitionProvider from '../../../providers/TransitionProvider';
import Loader from '../Loader/Loader';
import Svg from '../Svg/Svg';

import styles from './DataLoader.module.scss';

function DataLoader({ loading, isEmpty }) {
    return (
        <TransitionProvider
            inProp={loading || isEmpty}
            style={'opacity'}
            // height={'300px'}
            className={styles['dataLoader']}
        >
            {loading ? (
                <Loader size={80} borderSize={8} />
            ) : (
                <Svg
                    className={styles['dataLoader__emptyIcon']}
                    id={emptyIcon}
                />
            )}
        </TransitionProvider>
    );
}

export default DataLoader;
