import { useEffect, useRef } from 'react';

const useOutsideClick = (onOutsideClick) => {
    const ref = useRef(null);

    useEffect(() => {
        const handleClick = (event) => {
            if (!ref.current?.contains(event.target)) {
                onOutsideClick();
            }
        };

        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [onOutsideClick]);

    return ref;
};

export default useOutsideClick;
