import {
    GET_QUOTE_ERROR,
    GET_QUOTE_LOADING_START,
    GET_QUOTE_SUCCESS,
    GET_SWAP_ERROR,
    GET_SWAP_LOADING_START,
    GET_SWAP_SUCCESS,
    GET_TOKENS_ERROR,
    GET_TOKENS_LOADING_START,
    GET_TOKENS_SUCCESS,
} from '../types';

const initialState = {
    quote: null,
    getQuoteLoading: false,
    getQuoteError: null,
    swap: null,
    getSwapLoading: false,
    getSwapError: null,
    tokens: [],
    getTokensLoading: false,
    getTokensError: null,
};

export const sniperReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_QUOTE_SUCCESS: {
            return {
                ...state,
                quote: payload,
                getQuoteLoading: false,
            };
        }

        case GET_QUOTE_LOADING_START: {
            return {
                ...state,
                getQuoteLoading: true,
                getQuoteError: null,
            };
        }

        case GET_QUOTE_ERROR: {
            return {
                ...state,
                getQuoteError: payload,
                getQuoteLoading: false,
            };
        }

        case GET_SWAP_SUCCESS: {
            return {
                ...state,
                swap: payload,
                getSwapLoading: false,
            };
        }

        case GET_SWAP_LOADING_START: {
            return {
                ...state,
                getSwapLoading: true,
                getSwapError: null,
            };
        }

        case GET_SWAP_ERROR: {
            return {
                ...state,
                getSwapError: payload,
                getSwapLoading: false,
            };
        }

        case GET_TOKENS_SUCCESS: {
            return {
                ...state,
                tokens: payload,
                getTokensLoading: false,
            };
        }

        case GET_TOKENS_LOADING_START: {
            return {
                ...state,
                getTokensLoading: true,
                getTokensError: null,
            };
        }

        case GET_TOKENS_ERROR: {
            return {
                ...state,
                getTokensError: payload,
                getTokensLoading: false,
            };
        }

        default:
            return state;
    }
};
