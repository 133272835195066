import React, { memo } from 'react';
import { crossIcon } from '../../../assets/svg';
import TransitionProvider from '../../../providers/TransitionProvider';
import Svg from '../Svg/Svg';

import styles from './styles.module.scss';

const ApplyPopup = memo(({ isOpened, onClose, text }) => {
    return (
        <TransitionProvider
            style={'top'}
            inProp={isOpened}
            className={styles['applyPopup']}
        >
            <div className={styles['applyPopup__container']}>
                <p className={styles['applyPopup__text']}>{text}</p>
                <button className={styles['applyPopup__btn']} onClick={onClose}>
                    <Svg
                        id={crossIcon}
                        className={styles['applyPopup__crossIcon']}
                    />
                </button>
            </div>
        </TransitionProvider>
    );
});

export default ApplyPopup;
