import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom';

import AuthBlock from '../../components/global/AuthBlock/AuthBlock';
import { forgotPassword } from '../../redux/action/auth';
import { resetPasswordPagePath } from '../../router/path';
import { formProps } from './formProps';

function ForgotPasswordPage() {
    const loading = useSelector((state) => state.auth.forgotPasswordLoading);
    const error = useSelector((state) => state.auth.forgotPasswordError);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = (formData) => {
        const onSuccess = () =>
            navigate(resetPasswordPagePath, { state: formData });
        dispatch(forgotPassword(formData, onSuccess));
    };

    return (
        <Scrollbar
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <AuthBlock
                {...formProps()}
                onSubmit={onSubmit}
                error={error}
                loading={loading}
            />
        </Scrollbar>
    );
}

export default ForgotPasswordPage;
