import { useNavigate } from 'react-router-dom';
import { fallbackIcon } from '../../../../assets/svg';
import { sniperWidgetPagePath } from '../../../../router/path';
import MainBtn from '../../../layout/MainBtn/MainBtn';
import Svg from '../../../layout/Svg/Svg';
import styles from './styles.module.scss';

const ErrorFallback = ({ errorDetails = 'Unexpected error...' }) => {
    const navigate = useNavigate();
    const onTryAnotherClick = () => {
        navigate(sniperWidgetPagePath);
    };

    return (
        <div className={styles.fallbackContainer}>
            <Svg id={fallbackIcon} />
            <h3>Произошла ошибка при загрузке данных.</h3>
            <h5>
                Попробуйте совершить другую сделку или сообщите о проблеме в
                поддержку.
            </h5>
            <h5>
                Причина ошибки:{' '}
                <span className={styles.fallbackReason}>
                    {errorDetails.charAt(0).toLocaleUpperCase() +
                        errorDetails.slice(1)}
                </span>
            </h5>
            <MainBtn onClick={onTryAnotherClick}>Вернуться на сайт</MainBtn>
        </div>
    );
};

export { ErrorFallback };
