import {
    GET_CEX_OUTFLOWS_ERROR,
    GET_CEX_OUTFLOWS_LOADING_START,
    GET_CEX_OUTFLOWS_SUCCESS,
} from '../types';
import { fetchRequest, getCEXOutflowsUrl } from './fetchTools';

export const getCEXOutflows = (payload) => (dispatch) => {
    dispatch({ type: GET_CEX_OUTFLOWS_SUCCESS, payload });
};

export const requestCEXOutflows = () => async (dispatch) => {
    dispatch({ type: GET_CEX_OUTFLOWS_LOADING_START });
    try {
        const data = await fetchRequest(getCEXOutflowsUrl);
        dispatch(getCEXOutflows(data || []));
    } catch (err) {
        console.log(err);
        dispatch({ type: GET_CEX_OUTFLOWS_ERROR });
    }
};
