import {
    CHANGE_NOTIFICATIONS_ERROR,
    CHANGE_NOTIFICATIONS_LOADING_START,
    CHANGE_TG_NOTIFICATIONS_SUCCESS,
    CHANGE_WEB_NOTIFICATIONS_SUCCESS,
    SET_PUSH_NOTIFICATIONS_DATA,
    SET_TG_NOTIFICATIONS,
    SET_WEB_NOTIFICATIONS,
    TOGGLE_NOTIFICATION,
    TOGGLE_NOTIFICATIONS_ERROR,
    TOGGLE_NOTIFICATIONS_LOADING_START,
    TOGGLE_NOTIFICATIONS_SUCCESS,
} from '../types';

const initialState = {
    firebaseToken: null,
    isPushEnabled: false,
    web: {
        arbitrage: false,
        closedArbitrage: false,
        tracker: false,
        cexOutflows: false,
    },
    tg: {
        arbitrage: false,
        closedArbitrage: false,
        tracker: false,
        cexOutflows: false,
    },
    loading: false,
    errors: [],
};

export const notificationsReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case TOGGLE_NOTIFICATION: {
            return {
                ...state,
                [payload.type]: {
                    ...state[payload.type],
                    [payload.section]: !state[payload.type][payload.section],
                },
            };
        }

        case SET_PUSH_NOTIFICATIONS_DATA: {
            return {
                ...state,
                firebaseToken: payload.token,
                isPushEnabled: payload.isPushEnabled,
            };
        }

        case TOGGLE_NOTIFICATIONS_LOADING_START:
            return { ...state, loading: true, error: null };

        case TOGGLE_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                isPushEnabled: action.payload.isPushEnabled,
                firebaseToken: action.payload.token,
            };

        case TOGGLE_NOTIFICATIONS_ERROR:
            return { ...state, loading: false, error: action.payload };

        case SET_WEB_NOTIFICATIONS: {
            return { ...state, web: payload };
        }

        case CHANGE_WEB_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                web: payload,
                loading: false,
            };
        }

        case CHANGE_NOTIFICATIONS_LOADING_START: {
            return { ...state, loading: true };
        }

        case CHANGE_NOTIFICATIONS_ERROR: {
            return { ...state, errors: payload, loading: false };
        }

        case SET_TG_NOTIFICATIONS: {
            return { ...state, tg: payload };
        }

        case CHANGE_TG_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                tg: payload,
                loading: false,
            };
        }

        default:
            return state;
    }
};
