import React from 'react';
import Svg from '../../../../layout/Svg/Svg';
import { compactSocialIcon } from '../../../../../assets/svg';
import { socials } from '../config';
import styles from './styles.module.scss';

const SocialLinks = ({ isCompact }) => {
    if (isCompact) {
        return (
            <div className={styles['sideBarLinks__compactSocialIcon']}>
                <Svg
                    className={styles['sideBarLinks__compactSocialBackground']}
                    id={compactSocialIcon}
                />
            </div>
        );
    }

    return (
        <div className={styles['sideBarLinks__socials']}>
            {socials.map(({ link, iconId }, index) => (
                <a
                    key={index}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles['sideBarLinks__social']}
                >
                    <Svg
                        className={styles['sideBarLinks__socialIcon']}
                        id={iconId}
                    />
                </a>
            ))}
        </div>
    );
};

export default SocialLinks;
