export const sectionsFullNames = {
    'cex-cex': 'CEX-CEX Arbitrage',
    'cex-dex': 'CEX-DEX Arbitrage',
    'closed_cex-cex': 'Closed CEX-CEX Arbitrage',
    'closed_cex-dex': 'Closed CEX-DES Arbitrage',
    cex_outflows: 'CEX Outflows',
    cexScreener: 'Tracker',
    funding: 'Funding',
    sniper: 'Sniper',
};

export const sectionPrices = {
    'cex-cex': 20,
    'cex-dex': 20,
    'closed_cex-cex': 20,
    'closed_cex-dex': 20,
    cex_outflows: 20,
    cexScreener: 20,
    funding: 20,
    sniper: 20,
};

export const infoMessages = {
    atLeastOne: 'Добавьте хотя бы один раздел для оформления подписки',
    success: 'Перейдите по ссылке, чтобы завершить оформление подписки',
    duplicate: 'Этот раздел уже добавлен',
    chooseOne: 'Выберите раздел',
};
