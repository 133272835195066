import React from 'react';
import MainBtn from '../../../layout/MainBtn/MainBtn';
import styles from '../SubscriptionBlock.module.scss';

const NewSection = ({ section, lvl, price, onRemove }) => {
    return (
        <div className={styles['subscriptionBlock__newSection']}>
            <span>
                {section} - {lvl}{' '}
                <span className={styles['subscriptionBlock__price']}>
                    ({price}$)
                </span>
            </span>
            <MainBtn onClick={onRemove}>Удалить</MainBtn>
        </div>
    );
};

export default NewSection;
