import React from 'react';

import {
    dexBotImage,
    dexBotImageWebp,
    dexBotMobImage,
} from '../../../assets/images';
import ImageWebp from '../../layout/ImageWebp/ImageWebp';

import styles from './MainPageArbitrageBot.module.scss';

function MainPageArbitrageBot() {
    return (
        <div className={styles['arbitrageBot']}>
            <h2 className={`${styles['arbitrageBot__title']} titleTxt`}>
                <span className={'blueText'}>DEX </span>
                Arbitrage Bot
            </h2>
            <p className={`${styles['arbitrageBot__contentText']} contentTxt`}>
                Мониторинг цен по всем DEX обменникам, как внутри одной блокчейн
                сети, так и между другими блокчейн сетями. Ведется полный учет
                всех комиссий, мостов и объема торгов для извлечения большего
                профита.
            </p>
            <ImageWebp
                srcSet={dexBotImageWebp}
                src={dexBotImage}
                alt="Bot"
                className={`${styles['arbitrageBot__image']} ${styles['arbitrageBot__image_desk']}`}
            />
            <ImageWebp
                src={dexBotMobImage}
                alt="Bot"
                className={`${styles['arbitrageBot__image']} ${styles['arbitrageBot__image_mob']}`}
            />
        </div>
    );
}

export default MainPageArbitrageBot;
