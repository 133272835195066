import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Scrollbar } from 'react-scrollbars-custom';

import AuthBlock from '../../../components/global/AuthBlock/AuthBlock';
import { login } from '../../../redux/action/auth';
import { adminNewsPagePath } from '../../../router/path';
import { formProps } from '../../LoginPage/formProps';

function AdminLoginPage(props) {
    const error = useSelector((state) => state.auth.loginError);
    const loading = useSelector((state) => state.auth.loginLoading);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onSubmit = (formData) => {
        const navigateToHome = () => navigate(adminNewsPagePath);
        dispatch(login(formData, navigateToHome, true));
    };

    return (
        <Scrollbar
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <AuthBlock
                {...formProps()}
                error={error}
                onSubmit={onSubmit}
                loading={loading}
            />
        </Scrollbar>
    );
}

export default AdminLoginPage;
