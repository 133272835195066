import { clockIcon, eyeIcon } from '../../../../assets/svg';
import {
    arbitrageTypes,
    cexOutflowTypes,
    fundingTypes,
    trackerTypes,
} from '../../../../constants';

export const configureFilters = (filters, type, currentBlockchain) => {
    if (currentBlockchain === 'Выберите blockchain') {
        currentBlockchain = '';
    }

    const chains = [
        {
            title: 'BSC',
            value: 'bsc',
        },
        {
            title: 'Ethereum',
            value: 'eth',
        },
        {
            title: 'Polygon',
            value: 'polygon',
        },
        {
            title: 'Arbitrum',
            value: 'arbitrum',
        },
        {
            title: 'Solana',
            value: 'solana',
        },
        {
            title: 'Base',
            value: 'base',
        },
        {
            title: 'Ton',
            value: 'ton',
        },
        {
            title: 'Tron',
            value: 'tron',
        },
        {
            title: 'ZkSync',
            value: 'zksync',
        },
        {
            title: 'Optimism',
            value: 'optimism',
        },
        {
            title: 'Celo',
            value: 'celo',
        },
    ];

    const exchanges = [
        {
            value: 'binance',
        },
        {
            value: 'bitfinex',
        },
        {
            value: 'bybit',
        },
        {
            value: 'gate',
        },
        {
            value: 'huobi',
        },
        {
            value: 'jupiter',
            onlyFor: arbitrageTypes.cexToDex,
        },
        {
            value: 'kucoin',
        },
        {
            value: 'mexc',
        },
        {
            value: 'okx',
        },
        {
            value: 'poloniex',
        },
        {
            value: 'bitget',
        },
        {
            value: 'lbank',
        },
        {
            value: 'bitmart',
        },
        {
            value: 'bingx',
        },
        {
            value: 'coinex',
        },
        {
            value: 'xt',
        },
    ];

    const sort = [
        {
            title: 'Profit',
            value: 'profit',
        },
        {
            title: 'Spotted',
            value: 'spotted',
        },
    ];

    const fundingKinds = [
        { title: 'Futures - Futures', value: 'fut-fut' },
        { title: 'Futures - Spot', value: 'fut-spot' },
        { title: 'Spot - Futures', value: 'spot-fut' },
    ];

    const fundingCheckboxes = [{ title: 'Только синхронно', value: 'sync' }];

    const filteredExchanges = exchanges
        .filter((item) => !item?.onlyFor || item.onlyFor === type)
        .map(({ value }) => ({
            title: value[0].toUpperCase() + value.slice(1),
            value,
        }));

    const chainsForCexOutflows = chains.filter(
        (item) => item.value === 'bsc' || item.value === 'eth'
    );

    return [
        type === arbitrageTypes.cexToCex ||
        type === arbitrageTypes.cexToDex ||
        type === cexOutflowTypes.cexOutflows ||
        type === fundingTypes.funding
            ? {
                  filterType: 'checkbox',
                  filterKey: 'exchanges',
                  filterLabel: 'Exchanges',
                  filterOptions: filteredExchanges,
                  selectedFilterOptions: filters?.exchanges,
              }
            : null,
        type === cexOutflowTypes.cexOutflows
            ? {
                  filterType: 'checkbox',
                  filterKey: 'blockchains',
                  filterLabel: 'Blockchains',
                  filterOptions: chainsForCexOutflows,
                  selectedFilterOptions: filters?.blockchains?.filter(
                      (item) => item === 'bsc' || item === 'eth'
                  ),
              }
            : null,
        type === arbitrageTypes.cexToDex
            ? {
                  filterType: 'checkbox',
                  filterKey: 'blockchains',
                  filterLabel: 'Blockchains',
                  filterOptions: chains,
                  selectedFilterOptions: filters?.blockchains?.filter(
                      (item) => item === 'bsc' || item === 'eth'
                  ),
              }
            : null,
        type === arbitrageTypes.dexToDex || type === arbitrageTypes.cexToDex
            ? {
                  filterType: 'select',
                  filterKey: 'blockchain',
                  filterLabel: 'Blockchain',
                  filterOptions: chains,
                  selectedFilterOption: currentBlockchain,
              }
            : null,
        type === fundingTypes.funding
            ? {
                  filterType: 'input',
                  filterKey: 'profit',
                  filterLabel: 'Спред',
                  filterSubLabel: 'Установите желаемый процент',
                  filterValue: filters?.profit,
              }
            : null,
        type === fundingTypes.funding
            ? {
                  filterType: 'input',
                  filterKey: 'course_spread',
                  filterLabel: 'Курсовой спред',
                  filterSubLabel: 'Установите желаемый процент',
                  filterValue: filters?.course_spread,
              }
            : null,
        type === fundingTypes.funding
            ? {
                  filterType: 'checkbox',
                  filterKey: 'funding_kinds',
                  filterLabel: 'Тип фандинга',
                  filterOptions: fundingKinds,
                  selectedFilterOption: filters?.funding_kinds,
              }
            : null,
        type === fundingTypes.funding
            ? {
                  filterType: 'checkbox',
                  filterKey: 'estimate',
                  filterLabel: 'Расчет',
                  filterOptions: fundingCheckboxes,
                  selectedFilterOption: filters?.estimate,
              }
            : null,
        type === arbitrageTypes.dexToDex ||
        type === arbitrageTypes.cexToDex ||
        type === arbitrageTypes.cexToCex ||
        type === trackerTypes.CEX ||
        type === trackerTypes.DEX
            ? {
                  filterType: 'input',
                  filterKey: 'profit',
                  filterLabel: 'Мониторинг цены',
                  filterSubLabel: 'Установите значение желаемой прибыли',
                  filterValue:
                      type === arbitrageTypes.cexToDex
                          ? filters?.blockchainFilters[currentBlockchain]
                                ?.Profit
                          : filters?.profit,
              }
            : null,
        type === trackerTypes.DEX
            ? {
                  filterType: 'input',
                  filterKey: 'min_tvl',
                  filterLabel: 'Минимальный TVL',
                  filterValue: filters?.min_tvl,
              }
            : null,
        type !== trackerTypes.CEX && type !== trackerTypes.DEX
            ? {
                  filterType: 'input',
                  filterKey: 'min_amount',
                  filterLabel: 'Min Обьем $',
                  filterValue:
                      type === arbitrageTypes.cexToDex
                          ? filters?.blockchainFilters[currentBlockchain]
                                ?.MinAmount
                          : filters?.min_amount,
              }
            : null,
        type !== trackerTypes.CEX && type !== trackerTypes.DEX
            ? {
                  filterType: 'input',
                  filterKey: 'max_amount',
                  filterLabel: 'Max Обьем $',
                  filterValue:
                      type === arbitrageTypes.cexToDex
                          ? filters?.blockchainFilters[currentBlockchain]
                                ?.MaxAmount
                          : filters?.max_amount,
              }
            : null,
        type !== cexOutflowTypes.cexOutflows
            ? {
                  filterType: 'input',
                  filterKey: 'hidden_time',
                  filterIcon: eyeIcon,
                  filterLabel: 'Ограничение времени пропуска сделки (min)',
                  filterValue: filters.hidden_time,
              }
            : null,
        {
            filterType: 'input',
            filterKey: 'update_time',
            filterIcon: clockIcon,
            filterLabel: ' Задержка обновлений (сек)',
            filterValue: filters?.update_time,
        },
        {
            filterType: 'select',
            filterKey: 'sort',
            filterLabel: 'Сортировка',
            filterOptions: sort,
            selectedFilterOption:
                type === arbitrageTypes.cexToDex
                    ? filters?.blockchainFilters[currentBlockchain]?.sort
                    : filters?.sort,
        },
    ];
};
