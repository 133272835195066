import React from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import SupportPageBlock from '../../components/SupportPage/SupportPageBlock/SupportPageBlock';

function SupportPage() {
    return (
        <Scrollbar
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <div className={'gradientBg'}>
                <div className="container">
                    <SupportPageBlock />
                </div>
            </div>
        </Scrollbar>
    );
}

export default SupportPage;
