import React from 'react';

const ActiveIndicator = () => (
    <svg width="9" height="48" viewBox="0 0 9 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_f_951_48448)">
            <rect x="-4" y="2.61914" width="7" height="41.9805" rx="3.5" fill="#899CFF"/>
        </g>
        <rect x="-4" y="2.61914" width="7" height="41.9805" rx="3.5" fill="#899CFF"/>
        <defs>
            <filter id="filter0_f_951_48448" x="-10" y="-3.38086" width="19" height="53.9805" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur_951_48448"/>
            </filter>
        </defs>
    </svg>
);

export default ActiveIndicator;
