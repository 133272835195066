import {
    GET_ARBITRAGE_ERROR,
    GET_ARBITRAGE_LOADING_START,
    GET_ARBITRAGE_SUCCESS,
} from '../types';
import {
    fetchRequest,
    getArbitrageUrl,
    getClosedArbitrageUrl,
} from './fetchTools';

export const getArbitrage = (payload) => (dispatch) => {
    dispatch({ type: GET_ARBITRAGE_SUCCESS, payload });
};

export const requestArbitrage = () => async (dispatch) => {
    dispatch({ type: GET_ARBITRAGE_LOADING_START });
    try {
        const { Data: data } = await fetchRequest(getArbitrageUrl);
        dispatch(getArbitrage(data || []));
    } catch (err) {
        dispatch({ type: GET_ARBITRAGE_ERROR });
    }
};

export const requestClosedArbitrage = () => async (dispatch) => {
    dispatch({ type: GET_ARBITRAGE_LOADING_START });
    try {
        const { Data: data } = await fetchRequest(getClosedArbitrageUrl);
        dispatch(getArbitrage(data || []));
    } catch (err) {
        dispatch({ type: GET_ARBITRAGE_ERROR });
    }
};
