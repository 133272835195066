import React from 'react';
import MainBtn from '../../../../layout/MainBtn/MainBtn';
import styles from './styles.module.scss';

const ModalButtons = ({ onCancel }) => {
    return (
        <div className={styles['modalButtons']}>
            <MainBtn type={'button'} onClick={onCancel} isPassive={true}>
                Отменить
            </MainBtn>
            <MainBtn type={'submit'}>Создать фильтр</MainBtn>
        </div>
    );
};

export default ModalButtons;
