import React, { useState } from 'react';
import { emailIcon, FirstName, Subject, telegramIcon } from '../../../assets/svg';
import { mailLink, telegramLink } from '../../../constants';
import Svg from '../../layout/Svg/Svg';
import Divider from '../../SettingsPage/Divider/Divider';

import styles from './SupportPageBlock.module.scss';

function SupportPageBlock() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <div className={styles['supportBlock']}>
                <h5
                    className={`${styles['supportBlock__routeTitle']} pageRouteTitle`}
                >
                    Support
                </h5>
                <div className={styles['supportBlock__container']}>
                    {/* First Block */}
                    <div className={styles['supportBlock__main']}>
                        <h3 className={styles['supportBlock__title']}>Our contacts</h3>
                        <p className={styles['supportBlock__text']}>
                            Contact us to discuss your questions or report an error.
                        </p>
                        <Divider />
                        <div className={styles['supportBlock__btns']}>
                            <p className={styles['supportBlock__btnLabel']}>OUR EMAIL</p>
                            <a href={mailLink} target={'_blank'} rel={'noreferrer'} className={styles['supportBlock__btn']}>
                                <Svg className={styles['supportBlock__btnIcon']} id={emailIcon} />
                                <p className={styles['supportBlock__btnText']}>
                                    <span className={styles['supportBlock__btnText_link']}>
                                        info@1whale.io
                                    </span>
                                </p>
                            </a>
                            <p className={styles['supportBlock__btnLabel']}>OUR EMAIL</p>
                            <a target={'_blank'} rel={'noreferrer'} className={styles['supportBlock__btn']} href={telegramLink}>
                                <Svg className={styles['supportBlock__btnIcon']} id={telegramIcon} />
                                <p className={styles['supportBlock__btnText']}>
                                    <span className={styles['supportBlock__btnText_link']}>
                                        @OneWhaleNews
                                    </span>
                                </p>
                            </a>
                        </div>
                    </div>

                    {/* Second Block */}
                    <div className={styles['supportBlock__main']}>
                        <h3 className={styles['supportBlock__title']}>Questions, Comments or Concerns?</h3>
                        <p className={styles['supportBlock__text']}>
                            Use the form below to get in touch. Our office hours are Monday through Friday, 9 am to 5 pm Eastern, and we attempt to respond to support requests within 1 business day.
                        </p>
                        <Divider />
                        <div className={styles['supportBlock__btns']}>
                            <p className={styles['supportBlock__btnLabel']}>YOUR EMAIL ADDRESS</p>
                            <div className={styles['supportBlock__btn']}>
                                <Svg className={styles['supportBlock__btnIcon']} id={emailIcon} />
                                <input 
                                    type="email"
                                    placeholder="Enter your email"
                                    className={styles['supportBlock__input']}
                                />
                            </div>

                            <p className={styles['supportBlock__btnLabel']}>FIRST NAME</p>
                            <div className={styles['supportBlock__btn']}>
                                <Svg className={styles['supportBlock__btnIcon']} id={FirstName} />
                                <input 
                                    type="text"
                                    placeholder="Enter your name"
                                    className={styles['supportBlock__input']}
                                />
                            </div>

                            <p className={styles['supportBlock__btnLabel']}>SUBJECT</p>
                            <div className={styles['supportBlock__btn']}>
                                <Svg className={styles['supportBlock__btnIcon']} id={Subject} />
                                <input 
                                    type="text"
                                    placeholder="Describe theme of topic"
                                    className={styles['supportBlock__input']}
                                />
                            </div>

                            <p className={styles['supportBlock__btnLabel']}>MESSAGE BODY</p>
                            <div className={styles['supportBlock__btn']}>
                                <textarea 
                                    placeholder="Give us your opinion"
                                    className={styles['supportBlock__textarea']}
                                />
                            </div>

                            <Divider />
                            
                            <button 
                                className={styles['supportBlock__sendbtn']}
                                onClick={() => setIsModalOpen(true)}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Success Modal */}
            <div className={`${styles['supportBlock__modal']} ${isModalOpen ? styles['supportBlock__modal_active'] : ''}`}>
                <div className={styles['supportBlock__modalContent']}>
                    <h3 className={styles['supportBlock__modalTitle']}>
                        Your request has been successfully sent
                    </h3>
                    <p className={styles['supportBlock__modalSubtitle']}>
                        Use the form below to get in touch. Our office hours are Monday through Friday, 9 am to 5 pm Eastern, and we attempt to respond to support requests within 1 business day.
                    </p>
                    <button 
                        className={styles['supportBlock__modalButton']}
                        onClick={() => setIsModalOpen(false)}
                    >
                        Go back
                    </button>
                </div>
            </div>
        </>
    );
}

export default SupportPageBlock;
