export const lsProps = {
    autoRefresh: 'autoRefresh',
    token: 'token',
    user: 'user',
    filters: 'filters',
    isRegistered: 'isRegistered',
    usePushNot: 'usePushNot',
    pushendpoint: 'pushendpoint',
    pushToken: 'pushToken',
};
