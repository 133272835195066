import React from 'react';

import {
    arrowDownWithLines,
    crossIcon,
    eyeIcon,
    reportFakeIcon,
} from '../../../../../assets/svg';
import {
    getTimeDifference,
    setDateText,
} from '../../../../../utils/functions/date';
import MainBtn from '../../../../layout/MainBtn/MainBtn';
import Svg from '../../../../layout/Svg/Svg';

import { trackerTypes } from '../../../../../constants';
import styles from './styles.module.scss';

const TrackerPageListItem = ({
    token,
    exchange,
    blockchain,
    current_price,
    last_price,
    tvl,
    timestamp,
    change,
    link,
    type,
    Item,
    onSendReportMessage,
    onAddToHidden,
    onAddToBlackList,
}) => {
    const spotedText = getTimeDifference(timestamp);
    const isUp = current_price > last_price;

    return (
        <div className={styles['trackerListItem']}>
            <div className={styles['trackerListItem__header']}>
                <h5 className={styles['trackerListItem__title']}>
                    {token?.toUpperCase().replace('USDT', '')}
                </h5>
                <div className={styles['trackerListItem__headerBtns']}>
                    <>
                        <button
                            className={styles['trackerListItem__hiderBtn']}
                            onClick={() => onSendReportMessage(Item)}
                        >
                            <Svg
                                id={reportFakeIcon}
                                className={
                                    styles['trackerListItem__reportIcon']
                                }
                            />
                        </button>
                        <button
                            onClick={() => {
                                onAddToHidden(Item);
                            }}
                            className={styles['trackerListItem__hiderBtn']}
                        >
                            <Svg
                                className={styles['trackerListItem__hideIcon']}
                                id={eyeIcon}
                            />
                        </button>
                        <button
                            className={styles['trackerListItem__hiderBtn']}
                            onClick={() => {
                                onAddToBlackList(token);
                            }}
                        >
                            <Svg
                                className={styles['trackerListItem__crossIcon']}
                                id={crossIcon}
                            />
                        </button>
                    </>
                </div>
            </div>

            <div
                className={`${styles['trackerListItem__resultBlock']} ${isUp ? styles['trackerListItem__resultBlock_up'] : styles['trackerListItem__resultBlock_fall']}`}
            >
                <p className={styles['trackerListItem__resultBlockText']}>
                    <span
                        className={
                            isUp
                                ? styles['trackerListItem__resultBlockText_up']
                                : styles[
                                      'trackerListItem__resultBlockText_fall'
                                  ]
                        }
                    >
                        {type === trackerTypes.CEX
                            ? 'Exchange: '
                            : 'Blockchain: '}
                    </span>
                    {(type === trackerTypes.CEX ? exchange : blockchain)
                        .charAt(0)
                        .toUpperCase() +
                        (type === trackerTypes.CEX
                            ? exchange
                            : blockchain
                        ).slice(1)}
                </p>
                <p className={styles['trackerListItem__resultBlockText']}>
                    <Svg
                        className={`${styles['trackerListItem__resultBlockIcon']}  ${
                            isUp
                                ? styles['trackerListItem__resultBlockIcon_up']
                                : styles[
                                      'trackerListItem__resultBlockIcon_fall'
                                  ]
                        }`}
                        id={arrowDownWithLines}
                    />
                    {change.toFixed(2)}%
                </p>
                <p className={styles['trackerListItem__resultBlockText']}>
                    <span
                        className={
                            isUp
                                ? styles['trackerListItem__resultBlockText_up']
                                : styles[
                                      'trackerListItem__resultBlockText_fall'
                                  ]
                        }
                    >
                        {type === trackerTypes.CEX
                            ? 'Current price: '
                            : 'Price: '}
                    </span>
                    {current_price}$
                </p>
                <p className={styles['trackerListItem__resultBlockText']}>
                    <span
                        className={
                            isUp
                                ? styles['trackerListItem__resultBlockText_up']
                                : styles[
                                      'trackerListItem__resultBlockText_fall'
                                  ]
                        }
                    >
                        {type === trackerTypes.CEX
                            ? 'Previous price: '
                            : 'TVL: '}
                    </span>
                    {type === trackerTypes.CEX ? last_price : tvl}$
                </p>
                <div>
                    <p className={styles['trackerListItem__resultBlockText']}>
                        <a
                            className={
                                styles['trackerListItem__resultBlockLinkText']
                            }
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {type === trackerTypes.CEX
                                ? 'Chart'
                                : 'Dex Screener'}
                        </a>
                    </p>
                </div>
            </div>

            <div className={styles['trackerListItem__footer']}>
                {type === trackerTypes.DEX && (
                    <MainBtn className={styles['trackerListItem__snipeBtn']}>
                        SNIPE
                    </MainBtn>
                )}
                <p className={styles['trackerListItem__footerText']}>
                    Spotted: {setDateText(spotedText)}
                </p>
            </div>
        </div>
    );
};

export default TrackerPageListItem;
