import cn from 'classnames';
import React from 'react';
import defaultTokenIcon from '../../../../assets/icons/sniper/default.svg';
import ExtendedInput from '../../../layout/ExtendedInput';
import styles from './styles.module.scss';

const TokenBlock = ({ type, amount, tokenName, onAmountChange, isActive }) => {
    const imgSrc = defaultTokenIcon;

    return (
        <div className={styles.section}>
            <div className={styles.price}>
                <h2 className={styles.title}>
                    {type[0].toLocaleUpperCase() + type.slice(1)}
                </h2>
                {isActive && onAmountChange ? (
                    <ExtendedInput
                        type="number"
                        onChange={onAmountChange}
                        className={styles.amount}
                        value={amount}
                    />
                ) : (
                    <p className={cn(styles.amount, styles.amount_inactive)}>
                        {amount}
                    </p>
                )}
            </div>

            <div className={styles.currency}>
                {imgSrc && (
                    <img src={imgSrc} alt={tokenName} className={styles.icon} />
                )}
                <span>{tokenName.toLocaleUpperCase()}</span>
            </div>
        </div>
    );
};

export default TokenBlock;
