import { getLSItem, setLSItem } from '../../utils/functions/localStorage';
import { lsProps } from '../../utils/lsProps';
import {
    CHANGE_FILTERS_ERROR,
    CHANGE_FILTERS_LOADING_START,
    CHANGE_FILTERS_SUCCESS,
    SET_AUTO_REFRESH,
    TOGGLE_AUTO_REFRESH,
} from '../types';
import { authConfig, createFilterUrl, fetchRequest } from './fetchTools';

export const changeFilters = (filters, clb) => async (dispatch, getState) => {
    dispatch({ type: CHANGE_FILTERS_LOADING_START });
    const user = getState().auth.user;
    const reqBody = {
        id: user.id,
        ...filters,
    };

    try {
        const res = await fetchRequest(
            createFilterUrl,
            'POST',
            reqBody,
            authConfig()
        );

        const {
            min_amount,
            max_amount,
            min_tvl,
            profit,
            hidden,
            blacklist,
            exchanges,
            blockchains,
            hidden_time,
            update_time,
            filters,
            sort,
        } = res.User;

        const newFilters = {
            min_amount,
            max_amount,
            min_tvl,
            profit,
            hidden: hidden || [],
            blacklist: blacklist || [],
            exchanges: exchanges || [],
            hidden_time,
            blockchains: blockchains || [],
            blockchainFilters: filters || {},
            update_time,
            sort,
        };

        dispatch({ type: CHANGE_FILTERS_SUCCESS, payload: newFilters });

        setLSItem(lsProps.filters, newFilters);

        if (clb) {
            clb();
        }
    } catch (error) {
        dispatch({ type: CHANGE_FILTERS_ERROR, error });
    }
};

export const setAutoRefresh = (isToggle) => (dispatch) => {
    const autoRefresh = getLSItem(lsProps.autoRefresh, true);
    if (isToggle) {
        dispatch({ type: TOGGLE_AUTO_REFRESH });
        setLSItem(lsProps.autoRefresh, !autoRefresh);
    } else {
        const payload = autoRefresh === undefined ? true : autoRefresh;
        if (payload !== autoRefresh) setLSItem(lsProps.autoRefresh, payload);
        dispatch({ type: SET_AUTO_REFRESH, payload });
    }
};
