import React from 'react';
import { Link } from 'react-router-dom';
import { navLogoIMG, navLogoCompactIMG } from '../../../../../assets/images';
import { mainPagePath } from '../../../../../router/path';
import styles from './styles.module.scss';

const SideBarHeader = ({ isCompact }) => (
    <div className={`${styles['sideBarLinks__header']} ${isCompact ? styles['sideBarLinks__header_compact'] : ''}`}>
        <Link to={mainPagePath}>
            <img
                src={isCompact ? navLogoCompactIMG : navLogoIMG}
                alt="Logo"
                className={`
                    ${styles['sideBarLinks__logoImage']}
                    ${isCompact ? styles['sideBarLinks__logoImage_compact'] : styles['sideBarLinks__logoImage_full']}
                `}
            />
        </Link>
        <b className={styles['sideBarLinks__line']} />
    </div>
);

export default SideBarHeader;
