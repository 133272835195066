import {
    GET_CEX_TRACKER_ERROR,
    GET_CEX_TRACKER_LOADING_START,
    GET_CEX_TRACKER_SUCCESS,
    GET_DEX_TRACKER_ERROR,
    GET_DEX_TRACKER_LOADING_START,
    GET_DEX_TRACKER_SUCCESS,
} from '../types';

const initialState = {
    cexTrackerData: [],
    getCexTrackerLoading: false,
    getCexTrackerError: null,

    dexTrackerData: [],
    getDexTrackerLoading: false,
    getDexTrackerError: null,
};

export const trackerReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_CEX_TRACKER_SUCCESS: {
            return {
                ...state,
                cexTrackerData: payload,
                getCexTrackerLoading: false,
            };
        }

        case GET_CEX_TRACKER_LOADING_START: {
            return {
                ...state,
                getCexTrackerLoading: true,
                getCexTrackerError: null,
            };
        }

        case GET_CEX_TRACKER_ERROR: {
            return {
                ...state,
                getCexTrackerError: payload,
                getCexTrackerLoading: false,
            };
        }

        case GET_DEX_TRACKER_SUCCESS: {
            return {
                ...state,
                dexTrackerData: payload,
                getDexTrackerLoading: false,
            };
        }

        case GET_DEX_TRACKER_LOADING_START: {
            return {
                ...state,
                getDexTrackerLoading: true,
                getDexTrackerError: null,
            };
        }

        case GET_DEX_TRACKER_ERROR: {
            return {
                ...state,
                getDexTrackerError: payload,
                getDexTrackerLoading: false,
            };
        }

        default:
            return state;
    }
};
